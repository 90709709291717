import React from 'react';
import Tooltip from '@mui/material/Tooltip';

import { dataNotAvailable } from '@/Components/Shared/common';

interface IProfileHeaderItemProps {
  Icon: React.ElementType;
  text: string;
  isHref?: boolean;
  tooltipText?: string;
  dataTestId?: string;
}

export const ProfileHeaderItem = ({ Icon, text, isHref, tooltipText, dataTestId }: IProfileHeaderItemProps) => {
  const hasData = !text.includes('undefined');

  const getContent = () => {
    if (!hasData) return dataNotAvailable();

    if (isHref)
      return (
        <a
          href={`${'https://' + text}`}
          target="_blank"
          rel="noopener noreferrer"
          className="underline min-h-[30px] ml-1"
        >
          {text}
        </a>
      );

    return <span className="text-[#484848] text-sm font-medium ml-1">{text}</span>;
  };

  return (
    <Tooltip
      title={tooltipText}
      arrow
    >
      <div
        className="flex items-center gap-1"
        data-testid={dataTestId}
      >
        <span>
          <Icon />
        </span>

        {getContent()}
      </div>
    </Tooltip>
  );
};
