import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, ChartOptions, Plugin } from 'chart.js';
import 'chart.js/auto';
import { isNil } from 'lodash';

import { COMPARISON_MAIN_PALETTE } from '../common/colors';
import { DownloadChartImageButton } from '../common/DownloadChartImageButton';
import { DownloadCsvButton } from '../../Buttons/DownloadCsvButton';
import { CompareButton } from '../common/CompareButton';
import { CustomLegend } from '../common/CustomLegend';
import { getUniqueLabels, groupLegendData } from '../common/utils';
import { ChartConfigType, ChartDatasetType, ChartLegendItem, DatumDatum, AxisFormat, Currency } from '@/types';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { formatNumericChartValue } from '@/Utils/charts';
import { CHART_COMPARE_LIMIT } from '@/constants';
import { CompareChartsType } from '@/types/state/charts';
import { useQueriesChartCompany } from '@/hooks/queries';

interface IProps {
  config: ChartConfigType;
  params?: { row: DatumDatum };
  section: string;
  data: ChartDatasetType;
  comparisonDatasets: ChartDatasetType[];
  handleDownloadXlsx: () => void;
  handleDownloadImage: (ref: React.RefObject<Chart>) => void;
  handleRemoveDataset: (data: CompareChartsType) => void;
}

const getOptions = (
  currency: Currency,
  companies: Record<string, string>,
  yAxisFormat: AxisFormat,
): ChartOptions<'bar'> => ({
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    legend: { display: false },
    title: {
      display: true,
      text: '',
    },
    tooltip: {
      enabled: true,
      position: 'nearest',
      yAlign: 'bottom',
      xAlign: 'center',
      caretSize: 8,
      caretPadding: 18,
      callbacks: {
        label: function (context) {
          if (context.parsed.y !== null) {
            const { label } = context.dataset;

            if (label) {
              const splited = label.split(':');
              const company = companies[splited[0]];

              return `${company}: ${formatNumericChartValue({
                value: context.parsed.y,
                yAxisType: yAxisFormat,
                currency,
              })}`;
            }
          }
        },
      },
    },
    // @ts-ignore
    customCanvasBackgroundColor: {
      color: 'white',
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: true,
        color: '#ddd',
      },
      border: {
        dash: [2, 2],
      },
      beginAtZero: true,
      ticks: {
        callback: (value) => formatNumericChartValue({ value, yAxisType: yAxisFormat, currency }),
      },
    },
  },
});

const plugin: Plugin<'bar'> = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, _, opts) => {
    const { ctx } = chart;

    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = opts.color || '#99ffff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

const getDatasets = (
  labels: (string | number)[],
  data: ChartDatasetType,
  comparisonDatasets: ChartDatasetType[],
  config: ChartConfigType,
) => {
  const createDataset = (dataset: ChartDatasetType, color: string) => {
    const dataMap = new Map(dataset.data.map((item) => [item[config.XAXIS], item[config.YAXIS]]));

    return {
      label: `${dataset.bainId}:${config.TITLE}`,
      data: labels.map((label) => {
        const value = dataMap.get(label);

        return !isNil(value) ? +value : null;
      }),
      backgroundColor: color,
    };
  };

  return [
    createDataset(data, COMPARISON_MAIN_PALETTE[0]),
    ...comparisonDatasets.map((dataset, index) => createDataset(dataset, COMPARISON_MAIN_PALETTE[index + 1])),
  ];
};

export const BarChart = ({
  config,
  data,
  comparisonDatasets,
  handleDownloadXlsx,
  handleDownloadImage,
  handleRemoveDataset,
}: IProps) => {
  const ref = useRef<Chart<'bar'>>(null);
  const { data: companies } = useQueriesChartCompany(data.bainId);
  const currency = useShallowSelector((state) => state.config.currency);
  const options = useMemo(
    () => getOptions(currency, companies, config.YAXIS_FORMAT),
    [companies, config.YAXIS_FORMAT, currency],
  );
  const isCompareDisabled = comparisonDatasets.length >= CHART_COMPARE_LIMIT;
  const [legends, setLegends] = useState<Map<string, ChartLegendItem[]>>(new Map());
  const labels = useMemo(() => getUniqueLabels(data, comparisonDatasets, config), [comparisonDatasets, config, data]);
  const datasets = useMemo(
    () => getDatasets(labels, data, comparisonDatasets, config),
    [comparisonDatasets, config, data, labels],
  );

  const chartData = useMemo(() => ({ labels, datasets }), [labels, datasets]);

  useEffect(() => {
    const groupedLegends = groupLegendData(chartData.datasets);

    setLegends(groupedLegends);
  }, [chartData]);

  return (
    <div>
      <div className="flex items-center justify-between px-6 py-3">
        <span
          className="text-[#484848] text-sm font-semibold"
          data-testid="chart-title"
        >
          {config.TITLE}
        </span>

        <div className="flex items-center gap-4">
          {/* {section === CHART_SECTIONS.FINANCIALS && (
            <span className="text-sm text-[#666]">Source: {dataSource}</span>
          )} */}

          <CompareButton
            disabled={isCompareDisabled}
            chartId={config.ID}
          />

          <DownloadChartImageButton
            onClick={() => handleDownloadImage(ref)}
            dataTestId="chart-download-image"
            id={`${config.SLUG}-chart-download-image`}
          />

          <DownloadCsvButton
            onClick={handleDownloadXlsx}
            dataTestId="chart-download-xlsx"
            id={`${config.SLUG}-chart-download-xlsx`}
          />
        </div>
      </div>

      <hr className="text-[#ddd]" />

      <div className="h-[400px] px-6 pb-4">
        <Bar
          ref={ref}
          data={chartData}
          options={options}
          plugins={[plugin]}
        />
      </div>

      <div className="px-6 py-2">
        <CustomLegend
          bainId={data.bainId}
          chartId={config.ID}
          groupedLegends={legends}
          onRemoveDataset={handleRemoveDataset}
        />
      </div>
    </div>
  );
};
