import React from 'react';
import { useHistory } from 'react-router';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link, Skeleton } from '@mui/material';
import classnames from 'classnames';

import { TabPanel } from '../common/TabPanel';
import { Tab as TabType } from '../types';
import { MultiyearFinancialsTab } from './MultiyearFinTab/MultiyearFinTab';
import MnATab from './MnATab/MnATab';
import { ChartTab } from './ChartTab';
import { OverviewTab } from './OverviewTab/OverviewTab';
import { GenericTableTab } from './GenericTableTab/GenericTableTab';
import { PivotTableTab } from './PivotTableTab/PivotTableTab';
import CompetitorFinderTab from './CompetitorFinderTab/CompetitorFinderTab';
import { SimilarTab } from './SimilarTab';
import { PeopleTab } from './PeopleTab';
import { createCompanyProfileLink } from '@/pages/company-profile';
import { ChartConfigType, DatumDatum } from '@/types';
import { MultipleCharts } from '@/Components/Shared/Charts';

export interface CompanyProfileTabsProps {
  bainId: string;
  tabs: TabType[];
  currentTab: TabType;
  isCompanyProfileLoading: boolean;
  isCompanyProfileDatasetsLoading: boolean;
  showConfidenceScore: boolean;
  params?: {
    row: DatumDatum;
    value?: string;
  };
  chartConfigs: ChartConfigType[];
}

const MisconfiguredComponent: React.FC<{ component: string }> = ({ component }) => {
  return (
    <div>
      <p>
        Misconfigured component: <code>{component}</code>. Please try again or contact support.
      </p>
    </div>
  );
};

const CompanyProfileTabs: React.FC<CompanyProfileTabsProps> = ({
  bainId,
  tabs,
  currentTab,
  params,
  isCompanyProfileLoading,
  isCompanyProfileDatasetsLoading,
  showConfidenceScore,
  chartConfigs,
}) => {
  const { push } = useHistory();

  const handleChange = (_: React.SyntheticEvent<Element, Event>, newIndex: number) =>
    push(createCompanyProfileLink(bainId, tabs[newIndex]));

  const handlePushToFinancials = () => {
    push(createCompanyProfileLink(bainId, tabs[1]));
  };

  const currentTabIndex = tabs.findIndex((tab) => currentTab.slug === tab.slug);

  const renderTabContent = (slug: string, label: string) => {
    const tabDef = tabs.find((tab) => tab.slug === slug);

    if (tabDef === undefined) {
      return null;
    }

    if (!tabDef.components) {
      return (
        <GenericTableTab
          bainId={bainId}
          dataset={slug.replaceAll('-', '_')}
        />
      );
    }

    return tabDef.components.map((component, idx) => {
      switch (component.component) {
        case 'OverviewTab':
          return (
            <OverviewTab
              key={component.component + idx}
              params={params}
              bainId={bainId}
              isCompanyProfileLoading={isCompanyProfileLoading}
              showConfidenceScore={showConfidenceScore}
              chartConfigs={chartConfigs.filter((item) => item.SECTION === label)}
              handlePushToFinancials={handlePushToFinancials}
            />
          );
        case 'MultiyearFinancialsTab':
          return (
            <MultiyearFinancialsTab
              key={component.component + idx}
              bainId={bainId}
              revenueMetadata={params?.row?.self_financials_revenue___metadata}
            />
          );
        case 'MnATab':
          return (
            <MnATab
              key={component.component + idx}
              bainId={bainId}
              isCompanyProfileLoading={isCompanyProfileLoading}
            />
          );
        case 'CompetitorFinderTab':
          return (
            <CompetitorFinderTab
              key={component.component + idx}
              bainId={bainId}
            />
          );

        case 'PivotTableTab':
          if (
            component.properties &&
            'dataset' in component.properties &&
            'pivotColumn' in component.properties &&
            'valueColumn' in component.properties
          ) {
            return (
              <PivotTableTab
                key={component.component + idx}
                bainId={bainId}
                dataset={''}
                pivotColumn={''}
                valueColumn={''}
                {...component.properties}
              />
            );
          } else {
            return (
              <MisconfiguredComponent
                key={component.component + idx}
                component={component.component}
              />
            );
          }

        case 'GenericTableTab':
          if (component.properties && 'dataset' in component.properties) {
            return (
              <GenericTableTab
                key={component.component + idx}
                bainId={bainId}
                dataset={''}
                {...component.properties}
              />
            );
          } else {
            return (
              <MisconfiguredComponent
                key={component.component}
                component={component.component}
              />
            );
          }

        case 'Charts':
          return (
            label !== 'Overview' && (
              <MultipleCharts
                key={component.component + idx}
                bainId={bainId}
                section={label}
                params={params}
                chartConfigs={chartConfigs.filter((item) => item.SECTION === label)}
              />
            )
          );

        case 'ChartTab':
          return (
            component.properties && (
              <ChartTab
                key={component.component + idx}
                bainId={bainId}
                section={label}
                config={component.properties as ChartConfigType}
              />
            )
          );

        case 'SimilarTab':
          return (
            <SimilarTab
              key={component.component + idx}
              bainId={bainId}
            />
          );

        case 'PeopleTab':
          return (
            <PeopleTab
              key={component.component + idx}
              bainId={bainId}
              tabs={component.properties?.tabs ?? []}
              params={params}
              chartConfigs={chartConfigs.filter((item) => item.SECTION?.includes(label))}
            />
          );

        default:
          return (
            <MisconfiguredComponent
              key={component.component + idx}
              component={component.component}
            />
          );
      }
    });
  };

  return (
    <>
      <Box
        px={3}
        className="flex flex-col pt-6"
      >
        {isCompanyProfileDatasetsLoading ? (
          <Skeleton
            width={600}
            height={28}
            className="bg-[#0000000f]"
            data-testid="skeleton"
          />
        ) : (
          <Tabs
            value={currentTabIndex}
            id="company-tab-container"
            onChange={handleChange}
            className="min-h-[auto] border-b border-[#ddd]"
            classes={{
              indicator: 'bg-[#2E3F4C]',
            }}
          >
            {tabs.map(({ label, id, disabled }) => (
              <Tab
                key={id}
                label={label}
                id={id}
                disabled={disabled}
                className={classnames(
                  'text-sm text-[#2E3F4C] font-normal p-0 justify-end mb-2 min-w-[auto] min-h-[auto] mr-10 tracking-wide',
                  {
                    'opacity-50': disabled,
                  },
                )}
              />
            ))}
          </Tabs>
        )}
      </Box>

      <div className="flex justify-end gap-2 pt-4 pb-2 pr-6">
        <div id="data-source" />
        <div id="chart-switcher" />
      </div>

      {tabs.map(({ id, slug, label, disabled }, index) => (
        <TabPanel
          key={id}
          value={currentTabIndex}
          index={index}
        >
          {disabled ? null : renderTabContent(slug, label)}
        </TabPanel>
      ))}

      {currentTabIndex === -1 && !isCompanyProfileDatasetsLoading ? (
        <div className="px-6 pt-4">
          Incorrect tab url. Go back to{' '}
          <Link onClick={() => push(createCompanyProfileLink(bainId, tabs[0]))}>default tab</Link>.
        </div>
      ) : null}
    </>
  );
};

export default CompanyProfileTabs;
