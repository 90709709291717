import React, { CSSProperties, useMemo } from 'react';

import { isNil } from 'lodash';
import { Tooltip } from '@mui/material';
import { getPreferredDataSource } from '../../MultiyearFinTab/use-data-source';
import { EstimatedSign } from '../../../common/EstimatedSign';
import { DatumDatum } from '@/types';
import { numberFormatterCustom, dataNotAvailable } from '@/Components/Shared/common';
import { CURRENCIES } from '@/constants';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { BRIGHT_QUERY_DATA_SOURCE, getYearFromMetadata } from '@/Utils/text';

interface IProps {
  params: { row: DatumDatum };
}

interface IOverviewCardProps {
  text: string;
  value: string;
  year: string;
  tooltipText?: string;
  isEstimated?: boolean;
  valueCssStyle?: CSSProperties;
}

export const OverviewCard = ({ text, value, year, tooltipText, isEstimated, valueCssStyle }: IOverviewCardProps) => {
  const hasData = !value.includes('undefined');

  return (
    <div className="w-full max-w-[33.3%] flex justify-between p-4 border border-[#f5f5f5] text-[#484848] bg-white shadow-[0_0_4px_0_rgba(95,95,95,0.25)] rounded">
      <div className="flex flex-col">
        <div className="pb-3">
          <EstimatedSign isEstimated={isEstimated}>
            <span className="font-medium text-sm">{text}</span>
          </EstimatedSign>
        </div>

        {hasData ? (
          <div className="flex items-baseline gap-3">
            <Tooltip
              title={tooltipText}
              arrow
            >
              <span
                className="text-3xl"
                style={valueCssStyle}
              >
                {value}
              </span>
            </Tooltip>
            <span className="text-sm">{year}</span>
          </div>
        ) : (
          dataNotAvailable()
        )}
      </div>

      {/* <div className="flex items-center">
        <IconButton size="small">
          <KeyboardArrowRightIcon />
        </IconButton>
      </div> */}
    </div>
  );
};

const getTooltipText = (num?: number) => (isNil(num) ? undefined : num.toLocaleString('en'));

export const OverviewCards = ({ params }: IProps) => {
  const currency = useShallowSelector((state) => CURRENCIES[state.config.currency].label);
  const year =
    getYearFromMetadata(params.row, 'self_financials_revenue___', 'self_financials_revenue___metadata')?.year || '';

  //TODO: refactor the way of checking if data exists
  const revenue = isNil(params.row.self_financials_revenue___)
    ? String(undefined)
    : `${currency}${numberFormatterCustom(params.row.self_financials_revenue___ ?? 0, 0)}`;
  const revenueCagr3yr = `${params.row.self_financials_revenueCagr_3yr__?.toFixed(0)}%`;
  const ebitdaMargin = `${params.row.self_financials_ebitdaMargin___?.toFixed(0)}%`;
  const netIncomeMargin = `${params.row.self_financials_netIncomeMargin___?.toFixed(0)}%`;

  const yearCagr3yr = useMemo(() => {
    if (!year) return year;

    const numericYear = Number(year);

    if (isNaN(numericYear)) return '';

    return `${numericYear - 3}-${numericYear}`;
  }, [year]);

  const isFinancialDataEstimated =
    getPreferredDataSource(params.row.self_financials_revenue___metadata) === BRIGHT_QUERY_DATA_SOURCE;

  return (
    <div className="flex gap-1 mb-5">
      <OverviewCard
        text="Revenue"
        value={revenue}
        year={year}
        tooltipText={getTooltipText(params.row.self_financials_revenue___)}
        isEstimated={isFinancialDataEstimated}
      />
      <OverviewCard
        text="Revenue CAGR 3YR"
        value={revenueCagr3yr}
        year={yearCagr3yr}
        tooltipText={getTooltipText(params.row.self_financials_revenueCagr_3yr__)}
        isEstimated={isFinancialDataEstimated}
      />
      <OverviewCard
        text="EBITDA Margin"
        value={ebitdaMargin}
        year={year}
        tooltipText={getTooltipText(params.row.self_financials_ebitdaMargin___)}
        isEstimated={isFinancialDataEstimated}
      />
      <OverviewCard
        text="Net Income Margin"
        value={netIncomeMargin}
        year={year}
        tooltipText={getTooltipText(params.row.self_financials_netIncomeMargin___)}
        isEstimated={isFinancialDataEstimated}
      />
    </div>
  );
};
