import React, { useMemo, useState } from 'react';
import Box from '@mui/material/Box';

import { difference } from 'lodash';
import { Tab as TabType } from './types';
import CompanyProfileTabs from './tabs/CompanyProfileTabs';
import { CompanyProfileHeader } from './CompanyProfileHeader/CompanyProfileHeader';
import { ErrorPage } from '@/Components/Shared/ErrorPage';
import { ERROR_PAGE_PROPS, HEADER_HEIGHT } from '@/constants';
import { AddToListDropdown } from '@/Components/Shared/CompaniesList/SelectCompaniesControls/AddToListDropdown';
import { useMenu } from '@/hooks/use-menu';
import { useQueryCompaniesLists } from '@/hooks/queries/companies-list/use-query-companies-lists';
import { useQueryCompanyProfile } from '@/hooks/queries/company-profile/use-query-company-profile';
import { ChartConfigType } from '@/types';
import { useQueryConfig } from '@/hooks/queries/config/use-query-config';
import { useQueryCompanyProfileDatasets } from '@/hooks/queries/company-profile/use-query-company-profile-datasets';
import { ucFirstLetter } from '@/Utils/text';
import { getTabsWithCharts } from '@/Utils/charts';
import { useQueryChartConfigs } from '@/hooks/queries';

const getChartTabs = (configs: ChartConfigType[]) =>
  configs
    .filter((item) => item.SECTION === null)
    .map((item) => ({
      components: [
        {
          component: 'ChartTab',
          properties: item,
        },
      ],
      datasets: [],
      id: `chart:${item.ID}`,
      label: item.TITLE,
      slug: `chart:${item.ID}`,
      disabled: false,
    }));

export const generateTab = (dataset: string) => {
  const formattedSlug = dataset.replaceAll('_', '-');

  return {
    slug: formattedSlug,
    id: formattedSlug,
    label: ucFirstLetter(dataset.replaceAll('_', ' ')),
  };
};

export interface CompanyProfileViewProps {
  bainId: string;
  currentTab: TabType;
}

const CompanyProfileView: React.FC<CompanyProfileViewProps> = ({ bainId, currentTab }) => {
  const { data: profile, isLoading: isCompanyProfileLoading } = useQueryCompanyProfile(bainId);
  const { isOpen, anchorEl, handleClick, handleClose } = useMenu();
  const query = useQueryCompaniesLists();
  const companiesLists = query.data ?? [];
  const [showConfidenceScore, setShowConfidenceScore] = useState(false);

  const params = useMemo(() => {
    const visualizationTable = profile?.find((item: { table: string }) => item.table === 'visualization');
    const data = visualizationTable?.data?.[0];

    if (!data) return undefined;

    return {
      row: { ...data },
      value: data.self_firmo_name___,
    };
  }, [profile]);

  const companyProfileConfig = useQueryConfig<{ tabs: TabType[] }>('company_profile');
  const { data: companyProfileDatasets, isLoading: isCompanyProfileDatasetsLoading } =
    useQueryCompanyProfileDatasets(bainId);

  const chartsQuery = useQueryChartConfigs();

  const chartConfigs = useMemo(() => chartsQuery.data ?? [], [chartsQuery.data]);

  if (!isCompanyProfileLoading && !params?.value) return <ErrorPage {...ERROR_PAGE_PROPS.COMPANY_PROFILE_NOT_FOUND} />;

  const configTabs = companyProfileConfig.data?.tabs || [];

  const customDatasets = configTabs.flatMap((tab) => tab.datasets || []);

  const genericDatasets = difference(companyProfileDatasets ?? customDatasets, customDatasets).map(generateTab);
  const customTabs = configTabs
    .map((tab) => ({
      ...tab,
      disabled:
        tab.datasets?.length === 0
          ? false
          : !tab.datasets?.some((dataset) => companyProfileDatasets?.includes(dataset)),
    }))
    .filter(
      (tab) =>
        ['overview', 'financial-details', 'mergers-and-acquisitions-details'].includes(tab.slug) || !tab.disabled,
    );
  const initial_tabs: TabType[] = [...customTabs, ...genericDatasets, ...getChartTabs(chartConfigs)];

  const tabs = getTabsWithCharts(initial_tabs, chartConfigs);

  return (
    <Box
      className="flex flex-col bg-[#FAFAFA]"
      style={{
        minHeight: `calc(100vh - ${HEADER_HEIGHT}px`,
      }}
    >
      <CompanyProfileHeader
        bainId={bainId}
        companiesLists={companiesLists}
        params={params}
        showConfidenceScore={showConfidenceScore}
        isCompanyProfileLoading={isCompanyProfileLoading}
        setShowConfidenceScore={setShowConfidenceScore}
        handleClick={handleClick}
        tabs={tabs}
      />

      <CompanyProfileTabs
        bainId={bainId}
        tabs={tabs}
        params={params}
        currentTab={currentTab}
        isCompanyProfileLoading={isCompanyProfileLoading}
        isCompanyProfileDatasetsLoading={isCompanyProfileDatasetsLoading}
        showConfidenceScore={showConfidenceScore}
        chartConfigs={chartConfigs}
      />

      <AddToListDropdown
        isOpen={isOpen}
        anchorEl={anchorEl}
        handleClose={handleClose}
        selectedCompanies={[bainId]}
        companiesLists={companiesLists}
        isCompanyProfile
      />
    </Box>
  );
};

export default CompanyProfileView;
