import { isArray } from 'lodash';
import { TElasticTableDataValue } from '@/types';

export const formatValue = (value: TElasticTableDataValue, numericPrecision?: number) => {
  if (!value) return '';
  if (isArray(value)) return value.join(' ');

  if (typeof value === 'number')
    return new Intl.NumberFormat('en-GB', {
      notation: 'standard',
      maximumFractionDigits: numericPrecision,
    }).format(Number(value));

  return value;
};

const isYearMonth = (value: string) => {
  const [year, month] = value.split('-');

  const numberYear = Number(year);
  const numberMonth = Number(month);

  if (
    year.length === 4 &&
    !isNaN(numberYear) &&
    month.length === 2 &&
    !isNaN(numberMonth) &&
    numberMonth >= 1 &&
    numberMonth <= 12
  )
    return true;

  return false;
};

export const formatMetadata = (metadata: TElasticTableDataValue) => {
  if (!metadata) return '';
  if (isArray(metadata)) return metadata.join(' ');

  if (typeof metadata === 'number')
    return new Intl.NumberFormat('en-GB', {
      notation: 'standard',
      maximumFractionDigits: 0,
    }).format(Number(metadata));

  if (isYearMonth(metadata)) {
    return new Intl.DateTimeFormat('en-US', {
      month: 'long',
      year: 'numeric',
    }).format(new Date(metadata + '-01'));
  }

  return metadata;
};

export const downloadCsv = (dataToCsv: Record<string, any>) => {
  const titleKeys = Object.keys(dataToCsv[0]);

  const refinedData = [];

  refinedData.push(titleKeys);

  dataToCsv.forEach((item: any) => {
    refinedData.push(Object.values(item));
  });

  let csvContent = '';

  refinedData.forEach((row) => {
    csvContent += row.join(',') + '\n';
  });

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
  const objUrl = URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.setAttribute('href', objUrl);
  link.setAttribute('download', 'Helix Find.csv');

  document.body.appendChild(link);
  link.click();
};
