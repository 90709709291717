import React, { useMemo } from 'react';

import { isNil } from 'lodash';
import { OverviewCard } from '../../OverviewTab/OverviewCards';
import { DatumDatum } from '@/types';
import { useQueryColumnMapper } from '@/hooks/queries/column-mapper/use-query-column-mapper';
import { getUnit } from '@/Utils/units';
import { NUMERIC_COLUMN_TYPES } from '@/constants';

const getTooltipText = (num?: number | null) => (isNil(num) ? undefined : num.toLocaleString('en'));

interface HighlightsProps {
  params: { row: DatumDatum };
  metrics: string[];
}

export const Highlights = ({ params, metrics }: HighlightsProps) => {
  const columnMapperQuery = useQueryColumnMapper();
  const columnMapper = useMemo(() => columnMapperQuery.data ?? [], [columnMapperQuery.data]);

  return (
    <div className="flex gap-2 mt-4">
      {metrics.map((backendId) => {
        const metricSettings = columnMapper.find((column) => column['Backend Name'] === backendId);
        const columnBackendId = backendId as keyof DatumDatum;
        const metricValue = params.row[columnBackendId];
        const columnMetadataId = `${backendId}metadata` as keyof DatumDatum;
        const metricMetadata = params.row[columnMetadataId];

        if (!metricSettings || isNil(metricValue) || typeof metricValue !== 'number') return null;

        const unit = getUnit(metricSettings.NUMERIC_TYPE);
        const roundedValue = Number(metricValue.toFixed(0));
        const isCurrency = unit === NUMERIC_COLUMN_TYPES.EUR || unit === NUMERIC_COLUMN_TYPES.USD;

        const getColor = (num?: number | null) => {
          if (!num) return;

          if (num > 0) return '#21A663';

          return '#D63333';
        };

        const addUnit = (content?: string | number) => {
          if (isCurrency) return `${unit}${content}`;

          return `${content}${unit}`;
        };

        return (
          <OverviewCard
            key={backendId}
            text={metricSettings['Display Name']}
            value={addUnit(roundedValue)}
            year={metricMetadata && typeof metricMetadata === 'string' ? metricMetadata : ''}
            tooltipText={addUnit(getTooltipText(metricValue))}
            valueCssStyle={{ color: getColor(metricValue) }}
          />
        );
      })}
    </div>
  );
};
