import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

import { getColor } from './utils';

export const ColorLegend = () => {
  const map = useMap();

  useEffect(() => {
    // @ts-ignore
    const legend = L.control({ position: 'bottomright' });

    legend.onAdd = () => {
      const div = L.DomUtil.create('div', 'info legend');
      const grades = [0, 10, 20, 50, 100, 200, 500, 1000];

      const labels = grades.map((grade, index) => {
        const nextGrade = grades[index + 1];
        const range = nextGrade ? `&ndash;${nextGrade}` : '+';

        return `
          <div class="legend-item">
            <i style="background: ${getColor(grade + 1)}"></i>
            ${grade}${range}
          </div>
        `;
      });

      div.innerHTML = labels.join('');

      return div;
    };

    legend.addTo(map);

    return () => {
      legend.remove();
    };
  }, [map]);

  return null;
};
