import React, { useCallback, useMemo, useState } from 'react';
import { GeoJSON, MapContainer, TileLayer } from 'react-leaflet';
import { Feature, Geometry } from 'geojson';
import { Layer, PathOptions } from 'leaflet';
import Slider from '@mui/material/Slider';
import { useDebounceValue } from 'usehooks-ts';

import { getColor, getEmployeesByCountry, getTimePoints } from './utils';
import { InfoControl } from './InfoControl';
import { ColorLegend } from './ColorLegend';
import { ChartConfigType, ChartDatasetType } from '@/types';
import { useQueryGeoJson } from '@/hooks/queries/config/use-query-geo-json';

import 'leaflet/dist/leaflet.css';
import './index.scss';

interface IProps {
  config: ChartConfigType;
  data: ChartDatasetType;
}

export const MapChart = (props: IProps) => {
  const query = useQueryGeoJson();
  const timePoints = useMemo(() => getTimePoints(props.data.data), [props.data.data]);
  const lastTimePointIndex = timePoints.length - 1;
  const [currentTimeIndex, setCurrentTimeIndex] = useState(lastTimePointIndex);
  const targetDate = timePoints[currentTimeIndex].value;
  const employeesByCountry = useMemo(
    () => getEmployeesByCountry(targetDate, props.data.data),
    [props.data.data, targetDate],
  );
  const geoJsonKey = useMemo(() => JSON.stringify(employeesByCountry), [employeesByCountry]);
  const [debounced] = useDebounceValue(geoJsonKey, 300);

  const handleTimeChange = (_event: Event, newValue: number | number[]) => {
    setCurrentTimeIndex(newValue as number);
  };

  const getCountryStyle = useCallback((feature?: Feature): PathOptions => {
    const countryName = feature?.properties?.ISO_A3 || '';
    const hasData = countryName in employeesByCountry;
    const employees = employeesByCountry[countryName];

    return {
      fillColor: hasData ? getColor(employees) : 'transparent',
      weight: 1,
      opacity: hasData ? 1 : 0.3,
      color: '#666',
      dashArray: '3',
      fillOpacity: hasData ? 0.5 : 0,
    };
  }, [employeesByCountry]);

  const onEachCountry = useCallback((feature: Feature<Geometry>, layer: Layer) => {
    const countryName = feature.properties?.ISO_A3 || '';

    if (countryName in employeesByCountry) {
      const employees = employeesByCountry[countryName];

      layer.on({
        mouseover: (e) => {
          e.target.setStyle({
            weight: 2,
            dashArray: '',
            fillOpacity: 0.9,
          });

          if (e.target._map.updateInfoControl) {
            e.target._map.updateInfoControl(`
              <h4>Geographic Footprint</h4>
              <b>${feature.properties?.ADMIN}</b><br/>
              ${employees.toLocaleString()} employees
            `);
          }
        },
        mouseout: (e) => {
          e.target.setStyle(getCountryStyle(feature));

          if (e.target._map.updateInfoControl) {
            e.target._map.updateInfoControl('Geographic Footprint');
          }
        },
      });
    }
  }, [employeesByCountry, getCountryStyle]);

  return (
    <div>
      <div className="flex items-center justify-between px-6 py-3">
        <span className="text-[#484848] text-sm font-semibold">{props.config.TITLE}</span>

        <div className="w-96 px-4">
          <Slider
            className="map-slider"
            value={currentTimeIndex}
            min={0}
            max={lastTimePointIndex}
            onChange={handleTimeChange}
            valueLabelDisplay="auto"
            valueLabelFormat={(index) => timePoints[index].label}
            marks={[]}
          />
        </div>
      </div>

      <hr className="text-[#ddd] mb-0" />

      <div className='h-[700px] w-full'>
        <MapContainer
          className='h-full'
          center={[50.0, 15.0]}
          zoom={2.6}
          scrollWheelZoom

          minZoom={1.5}
          maxBounds={[[-90, -180], [85, 180]]}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
            noWrap={true}
          />

          {query.isFetched && query.data && (
            <GeoJSON
              key={debounced}
              data={query.data}
              style={getCountryStyle}
              onEachFeature={onEachCountry}
            />
          )}

          <ColorLegend />
          <InfoControl />
        </MapContainer>
      </div>
    </div>
  );
};
