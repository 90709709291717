import { NUMERIC_COLUMN_TYPES } from '@/constants';
import { ColumnFilterType } from '@/types';

export const getUnit = (type: ColumnFilterType) => {
  switch (type) {
    case NUMERIC_COLUMN_TYPES.PERCENTAGE:
      return '%';
    case NUMERIC_COLUMN_TYPES.EUR:
      return '€';
    case NUMERIC_COLUMN_TYPES.USD:
      return '$';
    default:
      return '';
  }
};
