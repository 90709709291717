import React, { FC, useCallback, useMemo } from 'react';
import { extend, isNil, orderBy, reduce } from 'lodash';
import classNames from 'classnames';
import { SkeletonLoader } from '../../common/SkeletonLoader';
import { downloadCsv, formatMetadata, formatValue } from './utils';
import { useQueryCompanyProfileDataset } from '@/hooks/queries/company-profile/use-query-company-profile-dataset';
import { useQueryColumnMapperDataset } from '@/hooks/queries/column-mapper/use-query-column-mapper-dataset';
import { DownloadCsvButton } from '@/Components/Shared/Buttons/DownloadCsvButton';

export interface GenericTilesTableProps {
  bainId: string;
  dataset: string;
  title: string;
  sortField: string;
  sortOrder?: 'asc' | 'desc';
  excludedColumns?: string[];
  disallowDownload?: boolean;
}

export const GenericTilesTable: FC<GenericTilesTableProps> = ({
  bainId,
  dataset,
  title,
  sortField,
  sortOrder = 'desc',
  excludedColumns = [],
  disallowDownload = false,
}) => {
  const { data: rawData, isLoading: isDataLoading } = useQueryCompanyProfileDataset({ bainId, dataset });
  const { data: datasetColumnMapper, isLoading: isDatasetColumnMapperLoading } = useQueryColumnMapperDataset(dataset);

  const isLoading = isDataLoading || isDatasetColumnMapperLoading;
  const data = useMemo(() => rawData ?? [], [rawData]);
  const columnMapper = useMemo(() => datasetColumnMapper ?? [], [datasetColumnMapper]);

  const sortedData = useMemo(() => orderBy(data, [sortField], [sortOrder]), [sortField, sortOrder, data]);

  const filteredColumnMapper = useMemo(
    () => columnMapper.filter((column) => !excludedColumns.includes(column['Backend Name'])),
    [columnMapper, excludedColumns],
  );

  const dataToDisplay = useMemo(() => {
    const latest = sortedData[0];

    if (!latest) return undefined;

    const sortedByFieldValue = latest[sortField];

    const items = Object.entries(latest)
      .map(([field, value]) => {
        const settings = filteredColumnMapper.find((column) => column['Backend Name'] === field);

        if (!settings || isNil(value)) return undefined;

        return {
          title: settings['Display Name'],
          value,
          metadata: sortedByFieldValue,
          rank: settings['Display Rank'],
          numericPrecision: settings.NUMERIC_PRECISION ?? 0,
        };
      })
      .filter((item) => !isNil(item));

    return orderBy(items, ['rank'], ['asc']);
  }, [filteredColumnMapper, sortField, sortedData]);

  const generateCsv = useCallback(() => {
    const latest = sortedData[0];

    if (!latest) return undefined;

    const sortedByFieldValue = latest[sortField];
    const sortedByFieldDisplayName = columnMapper.find((column) => column['Backend Name'] === sortField)?.[
      'Display Name'
    ];

    const dataToCsv = [
      ...(sortedByFieldDisplayName
        ? [
            {
              [sortedByFieldDisplayName]: sortedByFieldValue,
            },
          ]
        : []),
      ...(dataToDisplay ?? []).map(({ title: dataToDisplayTitle, value }) => ({ [dataToDisplayTitle]: value })),
    ];

    downloadCsv([reduce(dataToCsv, extend)]);
  }, [columnMapper, dataToDisplay, sortField, sortedData]);

  if (isLoading) {
    return <SkeletonLoader />;
  }

  if (!data || !datasetColumnMapper) {
    return <div>No data for {dataset}. Please contact system administrator.</div>;
  }

  if (!bainId || !dataset) {
    return null;
  }

  return (
    <div className="my-5 w-1/2 bg-white border-[#F5F5F5] rounded shadow-[0_1px_3px_0px_rgba(46,48,48,0.14)]">
      <div className="px-5 py-4 flex justify-between items-center border-b border-b-[#DDDDDD] min-h-[68px]">
        <span className="text-[#484848] text-sm font-medium">{title}</span>
        {dataToDisplay?.length ? (
          <div>
            <DownloadCsvButton
              id={`${dataset}-tiles-table-download-csv`}
              onClick={() => generateCsv()}
              disabled={disallowDownload}
              tooltipTitle={
                disallowDownload
                  ? 'Download is disallowed for this dataset. Reach us if you are interested in raw data.'
                  : 'Download Data'
              }
            />
          </div>
        ) : null}
      </div>

      <div className="px-5 py-4 flex flex-col">
        {dataToDisplay ? (
          dataToDisplay.map(({ title: rowTitle, value, metadata, numericPrecision }, index, array) => {
            const isLast = index === array.length - 1;

            return (
              <div
                key={rowTitle}
                className={classNames('mb-5', {
                  'border-b border-b-[#F5F5F5] pb-5 ': !isLast,
                })}
              >
                <span className="text-[#484848] text-sm font-medium">{rowTitle}</span>
                <div className="flex gap-3">
                  <span className="text-[#484848] text-3xl font-normal mt-2">
                    {formatValue(value, numericPrecision)}
                  </span>
                  <span className="text-[#484848] text-sm font-normal self-end">{formatMetadata(metadata)}</span>
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-[#484848] text-sm font-medium">No data available</div>
        )}
      </div>
    </div>
  );
};
