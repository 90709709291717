import React from 'react';
import { IconWithTooltip } from '../IconWithTooltip/IconWithTooltip';
import { DATA_SOURCE_ID } from '@/constants';

interface IProps {
  columnId: DATA_SOURCE_ID;
  size?: 'small' | 'medium';
  title?: string;
  sourceId?: string;
}

export const SourceLogo = ({ columnId, size = 'medium', sourceId, title }: IProps) => (
  <IconWithTooltip
    iconUrl={`/data-sources/${columnId}.png`}
    tooltip={
      <div className="flex flex-col gap-1">
        <span>{title}</span>

        {sourceId && <span>Company ID: {sourceId}</span>}
      </div>
    }
    size={size}
  />
);
