import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import { Typography, Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';

import { SkeletonLoader } from '../../common/SkeletonLoader';
import { useFinancialDataSource } from './use-data-source';
import { MultiyearFinTable } from './MultiyearFinTable';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { actions as configActions } from '@/slices/config';
import { actions as searchActions } from '@/slices/search';
import { CURRENCIES } from '@/constants';
import { useQueryCompanyMultiyearFinancials } from '@/hooks/queries/company-profile/use-query-company-multiyear-financials';
import { isCurrency } from '@/Utils/currency';
import { DataSourceMenu } from '@/Components/Shared/DataSourceMenu';
import { DataSourceEnum, FinancialDataSourceType } from '@/types';

const PERIOD_TYPES = {
  ANNUAL: 'Annual',
  QUARTERLY: 'Quarterly',
} as const;

type PeriodTypesKeys = keyof typeof PERIOD_TYPES;

export type PeriodTypes = typeof PERIOD_TYPES[PeriodTypesKeys];

const ORDER = {
  ASCENDING: 'asc',
  DESCENDING: 'desc',
} as const;

type OrderTypesKeys = keyof typeof ORDER;

export type OrderTypes = typeof ORDER[OrderTypesKeys];

const checkHasQuarterlyData = (financialPeriodsAvailable: string[], currentDataSource: FinancialDataSourceType) => {
  return (
    financialPeriodsAvailable.some((item) => item.startsWith(PERIOD_TYPES.QUARTERLY)) &&
    currentDataSource !== DataSourceEnum.GAIN_PRO
  );
};

interface MultiyearFinancialsTabProps {
  bainId: string;
  revenueMetadata?: string;
}

export const MultiyearFinancialsTab = ({ bainId, revenueMetadata }: MultiyearFinancialsTabProps) => {
  const dispatch = useDispatch();
  const currency = useShallowSelector((state) => state.config.currency);
  const [financialPeriod, setFinancialPeriod] = useState<PeriodTypes>(PERIOD_TYPES.ANNUAL);
  const [order, setOrder] = useState<OrderTypes>(ORDER.DESCENDING);
  const { data: multiYear, isLoading: isMultiYearLoading } = useQueryCompanyMultiyearFinancials(bainId);
  const {
    currentDataSource,
    dataSourcesAvailable,
    handleDataSourceChange,
    isFinancialDataEstimated,
    preferredDataSource,
  } = useFinancialDataSource(multiYear, revenueMetadata);

  useEffect(() => {
    if (financialPeriod === PERIOD_TYPES.QUARTERLY && currentDataSource === DataSourceEnum.GAIN_PRO) {
      setFinancialPeriod(PERIOD_TYPES.ANNUAL);
    }
  }, [currentDataSource, financialPeriod]);

  const handleFinancialPeriodChange = (
    _: React.MouseEvent<HTMLElement, MouseEvent>,
    newFinancialPeriod: PeriodTypes,
  ) => {
    if (newFinancialPeriod !== null) {
      setFinancialPeriod(newFinancialPeriod);
    }
  };

  const handleOrderChange = (_: React.MouseEvent<HTMLElement, MouseEvent>, newOrder: OrderTypes) => {
    if (newOrder !== null) {
      setOrder(newOrder);
    }
  };

  const handleCurrencyChange = (_: React.MouseEvent<HTMLElement, MouseEvent>, newCurrency: string) => {
    if (isCurrency(newCurrency)) {
      dispatch(configActions.setCurrency(newCurrency));
      dispatch(searchActions.setIsElasticEnabled(true));
    }
  };

  if (isMultiYearLoading) {
    return <SkeletonLoader />;
  }

  if (isEmpty(multiYear)) {
    return <div>No financials data available</div>;
  }

  const financialPeriodsAvailable = Object.keys(multiYear);
  const hasQuarterlyData = checkHasQuarterlyData(financialPeriodsAvailable, currentDataSource);
  const expected = ['IS', 'BS', 'CashFlow', 'Profitability', 'Other']; // TODO: from config
  const tables = financialPeriodsAvailable
    .filter((section) => section.startsWith(financialPeriod))
    .sort((s1, s2) => {
      let p1 = expected.indexOf(s1.split('_')[1]);

      if (p1 === -1) p1 = Infinity;

      let p2 = expected.indexOf(s2.split('_')[1]);

      if (p2 === -1) p2 = Infinity;

      return p1 - p2;
    });

  return (
    <div>
      <div className="mb-4 flex justify-between items-center">
        <Typography className="self-center">
          The following data has been calculated using Fiscal year (taking precedence over Calendar year) where
          applicable.
        </Typography>

        <div className="flex gap-3 items-center">
          {currentDataSource &&
            createPortal(
              <DataSourceMenu
                currentDataSource={currentDataSource}
                dataSourcesAvailable={dataSourcesAvailable}
                preferredDataSource={preferredDataSource}
                inputLabelId="fintab-data-source-label"
                selectLabelId="fintab-data-source-label"
                selectId="fintab-data-source"
                onChange={handleDataSourceChange}
              />,
              document.getElementById('data-source') as HTMLElement,
            )}
          <div>
            <Stack
              direction="row"
              spacing={2}
            >
              <ToggleButtonGroup
                value={financialPeriod}
                exclusive
                onChange={handleFinancialPeriodChange}
                aria-label="period"
                size="small"
                data-testid="multiyear-period-change"
              >
                <ToggleButton
                  value={PERIOD_TYPES.ANNUAL}
                  aria-label={`left ${PERIOD_TYPES.ANNUAL}`}
                  sx={{ padding: '6px', paddingX: '12px' }}
                  disabled={financialPeriod === PERIOD_TYPES.ANNUAL}
                >
                  {PERIOD_TYPES.ANNUAL}
                </ToggleButton>
                <ToggleButton
                  value={PERIOD_TYPES.QUARTERLY}
                  aria-label={`right ${PERIOD_TYPES.QUARTERLY}`}
                  sx={{ padding: '6px', paddingX: '12px' }}
                  disabled={financialPeriod === PERIOD_TYPES.QUARTERLY || !hasQuarterlyData}
                >
                  {PERIOD_TYPES.QUARTERLY}
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </div>
          <div>
            <Stack
              direction="row"
              spacing={2}
            >
              <ToggleButtonGroup
                value={order}
                exclusive
                onChange={handleOrderChange}
                aria-label="order"
                size="small"
                data-testid="multiyear-order-change"
              >
                <ToggleButton
                  value={ORDER.DESCENDING}
                  aria-label={`left ${ORDER.DESCENDING}`}
                  sx={{ padding: '6px', paddingX: '12px' }}
                  disabled={order === ORDER.DESCENDING}
                >
                  Desc
                </ToggleButton>
                <ToggleButton
                  value={ORDER.ASCENDING}
                  aria-label={`right ${ORDER.ASCENDING}`}
                  sx={{ padding: '6px', paddingX: '12px' }}
                  disabled={order === ORDER.ASCENDING}
                >
                  Asc
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
          </div>
          <div>
            <Stack
              direction="row"
              spacing={2}
            >
              <ToggleButtonGroup
                value={currency}
                exclusive
                onChange={handleCurrencyChange}
                aria-label="currency"
                size="small"
                data-testid="multiyear-currency-change"
              >
                {Object.entries(CURRENCIES).map(([key, val]) => (
                  <ToggleButton
                    key={key}
                    value={key}
                    aria-label={key}
                    sx={{ padding: '6px', paddingX: '14px' }}
                    disabled={currency === key}
                  >
                    {val.label}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Stack>
          </div>
        </div>
      </div>

      {tables.map((tableName) => {
        const tableData = multiYear[tableName];

        if (!tableData) return null;

        return (
          <MultiyearFinTable
            key={tableName}
            tableName={tableName}
            tableData={tableData}
            currency={currency}
            currentDataSource={currentDataSource}
            order={order}
            isEstimated={isFinancialDataEstimated}
          />
        );
      })}
    </div>
  );
};
