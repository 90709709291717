import { HELIX_APPS } from '@/constants';

export const HELIX_TOOLS = [
  {
    id: 0,
    label: 'Find',
    url: HELIX_APPS.FIND.link,
    text: 'Access millions of company profiles. Generate lists for diligences, deal sourcing, sector profiles, and customer targeting.',
    badge: { text: 'Popular', color: 'bg-[#0484e7]' },
  },
  {
    id: 1,
    label: 'Competitor Finder',
    url: HELIX_APPS.COMPETITOR_FINDER.link,
    text: 'A data-driven approach to finding competitors, leveraging GenAI/Machine Learning.',
  },
];

export const HELIX_OFFERINGS = [
  {
    id: 0,
    label: 'Centre of Excellence',
    url: HELIX_APPS.CENTRE_OF_EXCELLENCE.link,
    text: 'Our specialist team is happy to help you with any advanced company analytics.',
    badge: { text: 'Chargeback', color: 'bg-[#2e3f4c]' },
  },
];
