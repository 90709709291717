import React, { useMemo, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, ChartOptions, Plugin } from 'chart.js';
import { groupBy, isNil, orderBy, uniq } from 'lodash';
import 'chart.js/auto';

import { DownloadChartImageButton } from '../common/DownloadChartImageButton';
import { DownloadCsvButton } from '../../Buttons/DownloadCsvButton';
// import { CompareButton } from '../common/CompareButton';
import { getChartColor } from '../common/colors';
import { ChartConfigType, ChartDatasetType, ChartDataType, Currency, DatumDatum } from '@/types';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { formatNumericChartValue } from '@/Utils/charts';
// import { CHART_COMPARE_LIMIT } from '@/constants';

interface IProps {
  config: ChartConfigType;
  params?: { row: DatumDatum };
  section: string;
  data: ChartDatasetType;
  comparisonDatasets: ChartDatasetType[];
  handleDownloadXlsx: () => void;
  handleDownloadImage: (ref: React.RefObject<Chart>) => void;
}

const getOptions = (config: ChartConfigType, currency: Currency): ChartOptions<'bar'> => ({
  maintainAspectRatio: false,
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    legend: {
      display: true,
      position: 'top',
    },
    title: {
      display: true,
      text: '',
    },
    tooltip: {
      enabled: true,
      itemSort: (a, b) => Number(b.raw) - Number(a.raw),
      filter: (data) => !isNil(data.raw),
      callbacks: {
        label: function (context) {
          if (context.parsed.y !== null) {
            const { label } = context.dataset;

            if (label) {
              const company = label.split(':')[0];

              return `${company}: ${formatNumericChartValue({
                value: context.parsed.y,
                yAxisType: config.YAXIS_FORMAT,
                currency,
              })}`;
            }
          }
        },
      },
    },
    // @ts-ignore
    customCanvasBackgroundColor: {
      color: 'white',
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        display: true,
        color: '#ddd',
      },
      border: {
        dash: [2, 2],
      },
      ...(config.YAXIS_FORMAT === 'percent' && {
        max: 100,
      }),
      beginAtZero: true,
      ticks: {
        callback: function (value) {
          return formatNumericChartValue({
            value,
            yAxisType: config.YAXIS_FORMAT,
            currency,
          });
        },
      },
    },
  },
});

const plugin: Plugin<'bar'> = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart, __, opts) => {
    const { ctx } = chart;

    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = opts.color || '#99ffff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

const getDatasets = (
  labels: (string | number)[],
  data: ChartDataType[],
  config: ChartConfigType,
  comparisonDatasets: ChartDataType[][],
) => {
  const aggregateBy = config.METADATA?.aggregate_by ?? 'COUNTRY';

  const createStackedDataset = (sourceData: ChartDataType[], datasetIndex: number) => {
    const grouped = groupBy(sourceData, aggregateBy);

    const sort = () => {
      const availableKeys = Object.keys(grouped);

      const sortedKeys: string[] = [];

      const entries = orderBy(Object.entries(grouped), [([, array]) => array.length], ['desc']);

      const xValues = entries?.[0]?.[1].map((item) => item[config.XAXIS]).reverse();

      xValues.forEach((xValue) => {
        if (sortedKeys.length === availableKeys.length) return;

        const entriesWithCurrentXValue = entries.filter(([, array]) =>
          array.find((item) => item[config.XAXIS] === xValue),
        );

        const sortedEntriesWithCurrentXValue = orderBy(
          entriesWithCurrentXValue,
          [([, array]) => array.find((item) => item[config.XAXIS] === xValue)?.[config.YAXIS]],
          ['desc'],
        );

        sortedEntriesWithCurrentXValue.forEach(([item]) => {
          if (sortedKeys.includes(item)) return;

          sortedKeys.push(item);
        });
      });

      return sortedKeys;
    };

    return sort().map((country, index) => {
      const items = grouped[country];
      const color = getChartColor(index);

      const dataPoints = labels.map((date) => {
        const item = items.find((it) => it[config.XAXIS] === date);

        return item ? item[config.YAXIS] : null;
      });

      return {
        label: datasetIndex === 0 ? country : `${country} (Compare ${datasetIndex})`,
        data: dataPoints,
        backgroundColor: color,
        stack: `Stack ${datasetIndex}`,
      };
    });
  };

  const mainDataset = createStackedDataset(data, 0);
  const newDatasets = comparisonDatasets.flatMap((dataset, index) => {
    return createStackedDataset(dataset, index + 1);
  });

  return [...mainDataset, ...newDatasets];
};

export const StackedBarChart = ({
  config,
  data,
  comparisonDatasets,
  handleDownloadXlsx,
  handleDownloadImage,
}: IProps) => {
  const ref = useRef<Chart<'bar'>>(null);
  const currency = useShallowSelector((state) => state.config.currency);
  const labels = useMemo(() => uniq(data.data.map((item) => item[config.XAXIS])), [config.XAXIS, data]);
  const datasets = useMemo(
    () =>
      getDatasets(
        labels,
        data.data,
        config,
        comparisonDatasets.map((item) => item.data),
      ),
    [labels, data, config, comparisonDatasets],
  );
  const options = useMemo(() => getOptions(config, currency), [config, currency]);
  const chartData = useMemo(() => ({ labels, datasets }), [labels, datasets]);
  // const isCompareDisabled = comparisonDatasets.length >= CHART_COMPARE_LIMIT;

  return (
    <div>
      <div className="flex items-center justify-between px-6 py-3">
        <span className="text-[#484848] text-sm font-semibold">{config.TITLE}</span>

        <div className="flex items-center gap-4">
          {/* <CompareButton chartId={config.ID} disabled={isCompareDisabled} /> */}

          <DownloadChartImageButton
            onClick={() => handleDownloadImage(ref)}
            dataTestId="chart-download-image"
            id={`${config.SLUG}-chart-download-image`}
          />

          <DownloadCsvButton
            onClick={handleDownloadXlsx}
            dataTestId="chart-download-xlsx"
            id={`${config.SLUG}-chart-download-xlsx`}
          />
        </div>
      </div>

      <hr className="text-[#ddd]" />

      <div className="h-[400px] px-6 pb-4">
        <Bar
          // @ts-ignore
          ref={ref}
          data={chartData}
          options={options}
          plugins={[plugin]}
        />
      </div>
    </div>
  );
};
