import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, ChartData, ChartOptions, Plugin } from 'chart.js';
import { groupBy, isNaN, isNil, uniq } from 'lodash';
import dayjs from 'dayjs';
import 'chart.js/auto';

import { CustomLegend } from '../common/CustomLegend';
import { groupLegendData } from '../common/utils';
import { DownloadChartImageButton } from '../common/DownloadChartImageButton';
import { DownloadCsvButton } from '../../Buttons/DownloadCsvButton';
import { CompareButton } from '../common/CompareButton';
import { CHART_PALETTE, interpolateHexColor } from '../common/colors';
import { ChartConfigType, ChartDatasetType, ChartDataType, ChartLegendItem, Currency } from '@/types';
import { useShallowSelector } from '@/hooks/use-shallow-selector';
import { formatNumericChartValue } from '@/Utils/charts';
import { CHART_COMPARE_LIMIT } from '@/constants';
import { CompareChartsType } from '@/types/state/charts';
import { useQueriesChartCompany } from '@/hooks/queries';

interface IProps {
  config: ChartConfigType;
  data: ChartDatasetType;
  comparisonDatasets: ChartDatasetType[];
  subType?: string;
  handleDownloadXlsx: () => void;
  handleDownloadImage: (ref: React.RefObject<Chart>) => void;
  handleRemoveDataset: (data: CompareChartsType) => void;
}

const getOptions = (
  config: ChartConfigType,
  currency: Currency,
  companies: Record<string, string>,
): ChartOptions<'bar'> => {
  const yAxes = config.METADATA?.Y_AXIS ?? [];
  const [left, right] = ['LEFT', 'RIGHT'].map((pos) => yAxes.find((item) => item['AXIS-POSITION'] === pos));
  const leftAxisTitle = yAxes
    .filter((item) => item['AXIS-POSITION'] === 'LEFT')
    .map((item) => item.TITLE)
    .join(' / ');

  const createAxis = (position: 'left' | 'right', axis?: typeof left) => ({
    type: 'linear' as const,
    position,
    title: {
      display: true,
      text: position === 'left' ? leftAxisTitle : axis?.TITLE,
      font: { family: 'Graphik' },
    },
    ticks: {
      callback: (value: unknown) => {
        return formatNumericChartValue({
          value: value as string,
          yAxisType: axis?.FORMAT,
          currency,
        });
      },
    },
    grid: {
      display: position === 'left',
      color: position === 'left' ? '#ddd' : undefined,
    },
    ...(position === 'left' && {
      border: { dash: [2, 2] },
      beginAtZero: true,
    }),
  });

  return {
    maintainAspectRatio: false,
    responsive: true,
    interaction: { mode: 'index', intersect: false },
    scales: {
      x: { grid: { display: false } },
      y: { display: false },
      left: createAxis('left', left),
      right: createAxis('right', right),
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (context) {
            if (context.parsed.y !== null) {
              const { label } = context.dataset;

              if (label) {
                const splited = label.split(':');
                const company = companies[splited[0]];
                const indicator = splited[1];

                const axis = yAxes.find((item) => item['AXIS-POSITION'] === context.dataset.yAxisID?.toUpperCase());

                return `${company} ${indicator}: ${formatNumericChartValue({
                  value: context.parsed.y,
                  yAxisType: axis?.FORMAT,
                  currency,
                })}`;
              }
            }
          },
        },
        filter: ({ raw }) => isNaN(Number(raw)) || (raw as number) > 0,
      },
    },
  };
};

const plugin: Plugin<'bar'> = {
  id: 'customCanvasBackgroundColor',
  beforeDraw: (chart) => {
    const { ctx } = chart;

    ctx.save();
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = '#fff';
    ctx.fillRect(0, 0, chart.width, chart.height);
    ctx.restore();
  },
};

const getUniqueLabels = (mainDataset: ChartDataType[], comparisonDatasets: ChartDataType[][], xAxisField: string) => {
  const labels = new Set<string>();

  mainDataset.forEach((item) => labels.add(item[xAxisField] as string));
  comparisonDatasets.forEach((dataset) => dataset.forEach((item) => labels.add(item[xAxisField] as string)));

  return Array.from(labels).sort((a, b) => dayjs(a).valueOf() - dayjs(b).valueOf());
};

const getStackedChartData = (
  data: ChartDatasetType,
  config: ChartConfigType,
  comparisonDatasets: ChartDatasetType[],
): ChartData<'bar'> => {
  const aggregateBy = config.METADATA?.aggregate_by ?? 'SERIES';
  const yAxes = config.METADATA?.Y_AXIS ?? [];
  const xAxisFieldName = config.METADATA?.X_AXIS[0]?.FIELD_NAME ?? '';
  const datasetsMapped = comparisonDatasets.map((item) => item.data);
  const labels = getUniqueLabels(data.data, datasetsMapped, xAxisFieldName);

  const createStackedDataset = (dataset: ChartDatasetType, datasetIndex: number) => {
    const grouped = groupBy(dataset.data, aggregateBy);
    const sortedSeries = Object.keys(grouped).sort();

    return sortedSeries.map((series, index, array) => {
      const items = grouped[series];
      const CHART_PALETTE_VALUES = Object.values(CHART_PALETTE);
      const palette = CHART_PALETTE_VALUES[datasetIndex % CHART_PALETTE_VALUES.length];
      const color = interpolateHexColor(palette[0], palette[palette.length - 1], index / array.length);

      const dataPoints = labels.map((label) => {
        const item = items.find((it) => it[xAxisFieldName] === label);

        return item ? Number(item.RAISED_AMOUNT) : null;
      });

      const yAxis = yAxes.filter(({ CHART_TYPE }) => CHART_TYPE?.toLowerCase() === 'bar');

      return {
        label: `${dataset.bainId}:${series}`,
        data: dataPoints,
        backgroundColor: color,
        stack: `Stack ${datasetIndex}`,
        order: 1,
        yAxisID: yAxis[0]?.['AXIS-POSITION']?.toLowerCase(),
      };
    });
  };

  const mainDataset = createStackedDataset(data, 0);
  const newDatasets = comparisonDatasets.flatMap((dataset, index) => createStackedDataset(dataset, index + 1));

  const createLineDataset = (dataset: ChartDatasetType, datasetIndex: number) => {
    return yAxes
      .filter(({ CHART_TYPE }) => CHART_TYPE?.toLowerCase() === 'line')
      .map((yItem) => {
        const isFirstIdx = datasetIndex === 0;
        const CHART_PALETTE_VALUES = Object.values(CHART_PALETTE);
        const palette = CHART_PALETTE_VALUES[datasetIndex];
        const color = isFirstIdx ? yItem.STYLE.COLOR : palette[0];

        const dataPoints = labels.map((label) => {
          const item = dataset.data.find((it) => it[xAxisFieldName] === label);

          return !isNil(item?.[yItem.FIELD_NAME]) ? Number(item[yItem.FIELD_NAME]) : null;
        });

        return {
          label: `${dataset.bainId}:${yItem.TITLE}`,
          type: 'line' as const,
          data: dataPoints,
          borderColor: color,
          backgroundColor: color + '90',
          pointRadius: 0,
          pointHoverBackgroundColor: '#0484e7',
          pointHoverBorderColor: '#fff',
          spanGaps: true,
          yAxisID: yItem['AXIS-POSITION'].toLowerCase(),
          order: 0,
        };
      });
  };

  const yAxesMainDatasets = createLineDataset(data, 0);
  const yAxesNewDatasets = comparisonDatasets.flatMap((dataset, index) => createLineDataset(dataset, index + 1));

  const datasets = [...mainDataset, ...newDatasets, ...yAxesMainDatasets, ...yAxesNewDatasets];

  // @ts-ignore
  return { labels, datasets };
};

const getNotStackedChartData = (
  data: ChartDatasetType,
  config: ChartConfigType,
  comparisonDatasets: ChartDatasetType[],
): ChartData<'bar'> => {
  const yAxes = config.METADATA?.Y_AXIS ?? [];
  const xAxisFieldName = config.METADATA?.X_AXIS[0]?.FIELD_NAME ?? '';
  const mainLabels = data.data.map((item) => item[xAxisFieldName]);
  const comparisonDataLabels = comparisonDatasets
    .map((item) => item.data)
    .flat()
    .map((dataset) => dataset[xAxisFieldName]);
  const labels = uniq([...mainLabels, ...comparisonDataLabels].sort());

  const createDataset = (dataset: ChartDatasetType, datasetIndex: number) =>
    yAxes.map((yItem) => {
      const chartType = yItem.CHART_TYPE?.toLowerCase();
      const isBar = chartType === 'bar';
      const isFirstIdx = datasetIndex === 0;
      const CHART_PALETTE_VALUES = Object.values(CHART_PALETTE);
      const palette = CHART_PALETTE_VALUES[datasetIndex];
      const color = isFirstIdx ? yItem.STYLE.COLOR : palette[0];

      const dataPoints = labels.map((label) => {
        const item = dataset.data.find((it) => it[xAxisFieldName] === label);

        return !isNil(item?.[yItem.FIELD_NAME]) ? Number(item[yItem.FIELD_NAME]) : null;
      });

      return {
        label: `${dataset.bainId}:${yItem.TITLE}`,
        type: chartType,
        data: dataPoints,
        ...(isBar && {
          backgroundColor: color,
          skipNull: true,
        }),
        ...(!isBar && {
          borderColor: color + '80',
          backgroundColor: color + '40',
          pointRadius: 0,
          pointHoverBackgroundColor: '#0484e7',
          pointHoverBorderColor: '#fff',
          spanGaps: true,
        }),
        yAxisID: yItem['AXIS-POSITION'].toLowerCase(),
        order: isBar ? 1 : 0,
      };
    });

  const datasets = [
    ...createDataset(data, 0),
    ...comparisonDatasets.flatMap((dataset, index) => createDataset(dataset, index + 1)),
  ];

  // @ts-ignore
  return { labels, datasets };
};

export const LineOverBarChart = ({
  data,
  comparisonDatasets,
  config,
  subType,
  handleDownloadXlsx,
  handleDownloadImage,
  handleRemoveDataset,
}: IProps) => {
  const ref = useRef<Chart<'bar'>>(null);
  const { data: companies } = useQueriesChartCompany(data.bainId);
  const currency = useShallowSelector((state) => state.config.currency);
  const options = useMemo(() => getOptions(config, currency, companies), [config, currency, companies]);
  const isCompareDisabled = comparisonDatasets.length > CHART_COMPARE_LIMIT;
  const [legends, setLegends] = useState<Map<string, ChartLegendItem[]>>(new Map());
  const isStacked = subType === 'STACKEDBARLINE';

  const stackedChartData = useMemo(
    () => getStackedChartData(data, config, comparisonDatasets),
    [comparisonDatasets, config, data],
  );

  const notStackedChartData = useMemo(
    () => getNotStackedChartData(data, config, comparisonDatasets),
    [comparisonDatasets, config, data],
  );

  const chartData = isStacked ? stackedChartData : notStackedChartData;

  useEffect(() => {
    const groupedLegends = groupLegendData(chartData.datasets);

    setLegends(groupedLegends);
  }, [chartData]);

  return (
    <div>
      <div className="flex items-center justify-between px-6 py-3">
        <span className="text-[#484848] text-sm font-semibold">{config.TITLE}</span>

        <div className="flex items-center gap-4">
          <CompareButton
            disabled={isCompareDisabled}
            chartId={config.ID}
          />

          <DownloadChartImageButton
            onClick={() => handleDownloadImage(ref)}
            dataTestId="chart-download-image"
            id={`${config.SLUG}-chart-download-image`}
          />

          <DownloadCsvButton
            onClick={handleDownloadXlsx}
            dataTestId="chart-download-xlsx"
            id={`${config.SLUG}-chart-download-xlsx`}
          />
        </div>
      </div>

      <hr className="text-[#ddd] mb-6" />

      <div className="h-[400px] px-6 pb-4">
        <Bar
          ref={ref}
          data={chartData}
          options={options}
          plugins={[plugin]}
        />
      </div>

      <div className="px-6 py-2">
        <CustomLegend
          bainId={data.bainId}
          chartId={config.ID}
          groupedLegends={legends}
          onRemoveDataset={handleRemoveDataset}
        />
      </div>
    </div>
  );
};
