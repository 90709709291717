import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import Rating from '@mui/material/Rating';
import _ from 'lodash';

import { NoData } from '../NoData';
import { WrongCompany } from './WrongCompany';
import { CompanyLogo } from '@/Components/Shared/CompanyLogo';
import { IconWebPage } from '@/Components/Icons';
import { ensureFullUrl, getPrettyLink } from '@/Utils';
import { ICompanySentiment, ICompanySentimentMatch } from '@/types';

interface IProps {
  data?: ICompanySentiment;
}

export const GlassdoorSentiment = ({ data }: IProps) => {
  const [selectedCompany, setSelectedCompany] = useState(data?.top_match);

  const onChangeCompany = (item: ICompanySentimentMatch) => {
    setSelectedCompany(item);
  };

  return (
    <>
      {_.isNil(selectedCompany) ? (
        <NoData />
      ) : (
        <>
          <div className="flex gap-6 items-center mb-4">
            <CompanyLogo logo={selectedCompany.squareLogo} />

            <div className="flex items-center">
              <p className="text-[#484848] font-medium text-lg">{selectedCompany.name}</p>
            </div>
          </div>

          <div className="flex gap-10 mb-7">
            {selectedCompany.website && (
              <div className="flex items-center gap-2">
                <IconWebPage />
                <a
                  href={ensureFullUrl(selectedCompany.website)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#666666] underline font-medium text-sm"
                >
                  {getPrettyLink(selectedCompany.website)}
                </a>
              </div>
            )}
          </div>

          <div className="mb-4">
            <div className="flex items-center gap-4 mb-4">
              <p className="text-4xl font-medium text-[#21a663]">{selectedCompany.overallRating}</p>
              <Rating
                className="h-[24px] text-[#21a663]"
                defaultValue={+selectedCompany.overallRating}
                precision={0.5}
                readOnly
              />
            </div>

            <p className="text-3xl text-[#484848] font-medium mb-5">{selectedCompany.ratingDescription}</p>

            <div className="flex flex-col gap-2">
              <p className="text-sm text-[#484848] font-medium">
                {selectedCompany.recommendToFriendRating}% would recommend to a friend
              </p>
              <p className="text-[#666666] text-sm">{selectedCompany.numberOfRatings} total reviews</p>
            </div>
          </div>

          <Divider className="my-6 border-[#f5f5f5]" />

          <div className="flex flex-col">
            <p className="mb-4 text-[#484848] font-medium text-sm">Ratings by category</p>

            <div className="grid grid-cols-2 gap-4">
              {[
                { label: 'Diversity and inclusion', rating: selectedCompany.diversityAndInclusionRating },
                { label: 'Compensation and benefits', rating: selectedCompany.compensationAndBenefitsRating },
                { label: 'Culture and values', rating: selectedCompany.cultureAndValuesRating },
                { label: 'Career opportunities', rating: selectedCompany.careerOpportunitiesRating },
                { label: 'Senior management', rating: selectedCompany.seniorLeadershipRating },
                { label: 'Work/Life balance', rating: selectedCompany.workLifeBalanceRating },
              ].map((item, index) => (
                <div
                  key={index}
                  className="flex gap-3"
                >
                  <span className="text-sm font-medium text-[#484848]">{item.rating}</span>
                  <span className="text-sm text-[#666666]">{item.label}</span>
                </div>
              ))}
            </div>
          </div>

          <Divider className="my-6 border-[#f5f5f5]" />

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-3.5">
              <span className="text-[#666666] text-sm">Provided by</span>

              <span>
                <img
                  src="/sentiment/glassdoor.png"
                  alt="glassdoor"
                  width={120}
                  height={36}
                />
              </span>
            </div>

            <WrongCompany
              currentCompanyId={selectedCompany.id}
              initialCompany={data?.top_match as ICompanySentimentMatch}
              alternativeMatches={data?.alternative_matches}
              onChangeCompany={onChangeCompany}
            />
          </div>
        </>
      )}
    </>
  );
};
