import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

interface InfoControlProps {
  position?: L.ControlPosition;
}

export const InfoControl = ({ position = 'topright' }: InfoControlProps) => {
  const map = useMap();
  const [content, setContent] = React.useState<string>('Geographic Footprint');

  useEffect(() => {
    // @ts-ignore
    const info = L.control({ position });

    info.onAdd = () => {
      const div = L.DomUtil.create('div', 'info');

      div.innerHTML = content;

      return div;
    };

    const updateContent = (newContent: string) => {
      setContent(newContent);
    };

    info.addTo(map);

    // @ts-ignore
    map.updateInfoControl = updateContent;

    return () => {
      info.remove();

      // @ts-ignore
      delete map.updateInfoControl;
    };
  }, [map, content, position]);

  return null;
};
