export interface IProfile {
  table: string;
  data: DatumDatum[];
}

export interface DatumDatum {
  self_ids_id__bdex_?: string;
  bain_id: number;
  execs_announce_primaryKeyID?: string;
  execs_announce_CompanyName?: string;
  execs_announce_CompanyID?: string;
  execs_announce_DirectorName?: string;
  execs_announce_DirectorID?: string;
  execs_announce_RoleName?: string;
  execs_announce_BainRoleName?: null | string;
  execs_announce_Description?: string;
  execs_announce_AnnouncementDate?: Date;
  execs_announce_AnnouncementDateFlag?: string;
  execs_announce_EffectiveDate?: Date;
  execs_announce_EffectiveDateFlag?: string;
  execs_announce_EffectiveDateRecency?: number;
  execs_announce_EffectiveDateRecencyCat?: ExecsAnnounceEffectiveDateRecencyCat;
  execs_ppl_PrimaryKeyID?: string;
  execs_ppl_RowType?: ExecsPplRowType;
  execs_ppl_CompanyID?: string;
  execs_ppl_CompanyName?: string;
  execs_ppl_RoleName?: string;
  execs_ppl_BainRoleName?: null | string;
  execs_ppl_FulltextDescription?: null | string;
  execs_ppl_BrdPosition?: ExecsPpl;
  execs_ppl_NED?: ExecsPpl;
  execs_ppl_RoleType?: ExecsPplRoleType;
  execs_ppl_DateStartRole?: Date;
  execs_ppl_DateStartRoleFlag?: string;
  execs_ppl_DateStartRoleDisplay?: string;
  execs_ppl_DateEndRole?: Date;
  execs_ppl_DateEndRoleFlag?: string;
  execs_ppl_DateEndRoleDisplay?: ExecsPplDateEndRoleDisplay;
  execs_ppl_DirectorID?: string;
  execs_ppl_DirectorName?: string;
  execs_ppl_Forename1?: string;
  execs_ppl_Surname?: string;
  execs_ppl_FamilyMember?: boolean;
  execs_ppl_Gender?: ExecsPplGender;
  execs_ppl_DOB?: Date;
  execs_ppl_Age?: number | null;
  execs_ppl_RoleTenure?: number | null;
  execs_ppl_CoTenure?: number | null;
  execs_ppl_boardServedAsNED?: null | string;
  execs_ppl_boardServedAsNEDID?: null | string;
  bain_id_buyer?: null;
  company_name?: string;
  buyer_name?: string;
  entry_date?: Date;
  exit_date?: null;
  ownership_pct?: null;
  transaction_value?: null;
  currently_in_portfolio?: string;
  data_source?: DataSource;
  self_ids_id__factiva_?: string;
  end_date?: Date;
  subject_name?: string;
  topic_grouping?: TopicGrouping;
  HitCount?: number;
  periodenddate?: Date;
  calendaryear?: number;
  calendarquarter?: number;
  metric?: Metric;
  units?: Units;
  value?: number;
  period_type?: PeriodType;
  statement?: Statement;
  rank?: number;
  self_logourl?: string;
  self_ids_id__cdp_?: number;
  self_ids_id__ciq_?: string;
  parent_ids_id__ciq_?: string;
  self_ids_id__cb_?: string;
  parent_ids_id__cb_?: string;
  self_ids_id__zi_?: string;
  parent_ids_id__zi_?: null;
  self_ids_id__arx_?: string;
  self_ids_id__gp_?: string;
  self_ids_id__bq_?: string;
  self_firm_typeIsPE___?: string;
  self_ids_ciqLatMatch___confidence?: string;
  self_ids_ciqLatMatch___metadata?: string;
  self_firmo_name___?: string;
  self_firmo_name___confidence?: number;
  self_firmo_name___metadata?: string;
  parent_firmo_name___?: string;
  parent_firmo_name___confidence?: number;
  parent_firmo_name___metadata?: string;
  self_firmo_webpage___?: string;
  self_firmo_webpage___confidence?: number;
  self_firmo_webpage___metadata?: string;
  self_firmo_yearFounded___?: string;
  self_firmo_yearFounded___confidence?: number;
  self_firmo_yearFounded___metadata?: string;
  self_location_region___?: string;
  self_location_region___confidence?: number;
  self_location_region___metadata?: string;
  self_location_country___?: string;
  self_location_country___confidence?: number;
  self_location_country___metadata?: string;
  self_location_countryCode___?: string;
  self_location_countryCode___confidence?: number;
  self_location_countryCode___metadata?: string;
  self_location_city___?: string;
  self_location_city___confidence?: number;
  self_location_city___metadata?: string;
  self_sector_sector___?: string;
  self_sector_sector___confidence?: number;
  self_sector_sector___metadata?: string;
  self_sector_industry___?: string;
  self_sector_industry___confidence?: number;
  self_sector_industry___metadata?: string;
  self_sector_industryGroup___?: string;
  self_sector_industryGroup___confidence?: number;
  self_sector_industryGroup___metadata?: string;
  self_sector_subIndustry___?: string;
  self_sector_subIndustry___confidence?: number;
  self_sector_subIndustry___metadata?: string;
  self_firm_typeL1___?: string;
  self_firm_typeL1___confidence?: number;
  self_firm_typeL1___metadata?: string;
  self_firm_typeL2___?: string;
  self_firm_typeL2___confidence?: number;
  self_firm_typeL2___metadata?: string;
  child_firmo_names___?: string;
  child_firmo_names___confidence?: number;
  child_firmo_names___metadata?: string;
  self_hierarchy_parentSubsStandalone___?: string;
  self_hierarchy_parentSubsStandalone___confidence?: number;
  self_hierarchy_parentSubsStandalone___metadata?: string;
  self_firmo_description___?: string;
  self_firmo_description___metadata?: string;
  self_firmo_description_long___?: null;
  self_firmo_description_long___metadata?: null;
  self_sector_sic2DigitDesc___?: string;
  self_sector_sic2DigitDesc___metadata?: string;
  self_sector_sic4DigitDesc___?: string;
  self_sector_sic4DigitDesc___metadata?: string;
  self_financials_revenue___?: number;
  self_financials_revenue___min?: number;
  self_financials_revenue___max?: number;
  self_financials_revenue___confidence?: number;
  self_financials_revenue___metadata?: string;
  self_firmo_employeeCount___?: number;
  self_firmo_employeeCount___min?: number;
  self_firmo_employeeCount___max?: number;
  self_firmo_employeeCount___confidence?: number;
  self_firmo_employeeCount___metadata?: string;
  self_financials_ebitda___?: number;
  self_financials_ebitda___min?: number;
  self_financials_ebitda___max?: number;
  self_financials_ebitda___confidence?: number;
  self_financials_ebitda___metadata?: string;
  self_financials_netIncome___?: number;
  self_financials_netIncome___min?: number;
  self_financials_netIncome___max?: number;
  self_financials_netIncome___confidence?: number;
  self_financials_netIncome___metadata?: string;
  self_financials_revenueCagr_1yr__?: number;
  self_financials_revenueCagr_1yr__min?: number;
  self_financials_revenueCagr_1yr__max?: number;
  self_financials_revenueCagr_1yr__confidence?: number;
  self_financials_revenueCagr_1yr__metadata?: string;
  self_financials_revenueCagr_3yr__?: number;
  self_financials_revenueCagr_3yr__min?: number;
  self_financials_revenueCagr_3yr__max?: number;
  self_financials_revenueCagr_3yr__confidence?: number;
  self_financials_revenueCagr_3yr__metadata?: string;
  parent_financials_revenueCagr_1yr__?: number;
  parent_financials_revenueCagr_1yr__min?: number;
  parent_financials_revenueCagr_1yr__max?: number;
  parent_financials_revenueCagr_1yr__confidence?: number;
  parent_financials_revenueCagr_1yr__metadata?: string;
  parent_financials_revenueCagr_3yr__?: number;
  parent_financials_revenueCagr_3yr__min?: number;
  parent_financials_revenueCagr_3yr__max?: number;
  parent_financials_revenueCagr_3yr__confidence?: number;
  parent_financials_revenueCagr_3yr__metadata?: string;
  child_hierarchy_count___?: number;
  child_hierarchy_count___min?: number;
  child_hierarchy_count___max?: number;
  child_hierarchy_count___confidence?: number;
  child_hierarchy_count___metadata?: string;
  child_hierarchy_countRevenue10PctOrMore___?: number;
  child_hierarchy_countRevenue10PctOrMore___min?: number;
  child_hierarchy_countRevenue10PctOrMore___max?: number;
  child_hierarchy_countRevenue10PctOrMore___confidence?: number;
  child_hierarchy_countRevenue10PctOrMore___metadata?: string;
  child_hierarchy_countEmpl10PctOrMore___?: number;
  child_hierarchy_countEmpl10PctOrMore___min?: number;
  child_hierarchy_countEmpl10PctOrMore___max?: number;
  child_hierarchy_countEmpl10PctOrMore___confidence?: number;
  child_hierarchy_countEmpl10PctOrMore___metadata?: string;
  child_financials_largestSubsRevenueAsPct___?: number;
  child_financials_largestSubsRevenueAsPct___min?: number;
  child_financials_largestSubsRevenueAsPct___max?: number;
  child_financials_largestSubsRevenueAsPct___confidence?: number;
  child_financials_largestSubsRevenueAsPct___metadata?: string;
  child_firmo_largestSubsEmployeesAsPct___?: number;
  child_firmo_largestSubsEmployeesAsPct___min?: number;
  child_firmo_largestSubsEmployeesAsPct___max?: number;
  child_firmo_largestSubsEmployeesAsPct___confidence?: number;
  child_firmo_largestSubsEmployeesAsPct___metadata?: string;
  self_mna_yrsOwnedByCurrentOwner___?: number;
  self_mna_yrsOwnedByCurrentOwner___min?: number;
  self_mna_yrsOwnedByCurrentOwner___max?: number;
  self_mna_yrsOwnedByCurrentOwner___confidence?: number;
  self_mna_yrsOwnedByCurrentOwner___metadata?: string;
  parent_mna_avgHoldPeriod___?: number;
  parent_mna_avgHoldPeriod___min?: number;
  parent_mna_avgHoldPeriod___max?: number;
  parent_mna_avgHoldPeriod___confidence?: number;
  parent_mna_avgHoldPeriod___metadata?: string;
  self_mna_yrsOwnedByCurrentOwnerVsOwnerAvg___?: number;
  self_mna_yrsOwnedByCurrentOwnerVsOwnerAvg___min?: number;
  self_mna_yrsOwnedByCurrentOwnerVsOwnerAvg___max?: number;
  self_mna_yrsOwnedByCurrentOwnerVsOwnerAvg___confidence?: number;
  self_mna_yrsOwnedByCurrentOwnerVsOwnerAvg___metadata?: string;
  self_firmo_sizePercentile___?: number;
  self_firmo_sizePercentile___min?: number;
  self_firmo_sizePercentile___max?: number;
  self_firmo_sizePercentile___confidence?: number;
  self_firmo_sizePercentile___metadata?: string;
  market_frag_revInSubIndustryRegionTotal___?: string;
  market_frag_revInSubIndustryRegionHHI___?: number;
  market_frag_revInSubIndustryRegionLargestMktShare___?: number;
  market_frag_revInSubIndustryRegionLargestCo___?: string;
  market_frag_revInSubIndustryRegionTotalCo___?: number;
  market_frag_revInSubIndustryCountryTotal___?: string;
  market_frag_revInSubIndustryCountryHHI___?: number;
  market_frag_revInSubIndustryCountryLargestMktShare___?: number;
  market_frag_revInSubIndustryCountryLargestCo___?: string;
  market_frag_revInSubIndustryCountryTotalCo___?: number;
  market_frag_emplInSubIndustryRegionTotal___?: string;
  market_frag_emplInSubIndustryRegionHHI___?: number;
  market_frag_emplInSubIndustryRegionLargestMktShare___?: number;
  market_frag_emplInSubIndustryRegionLargestCo___?: string;
  market_frag_emplInSubIndustryRegionTotalCo___?: number;
  market_frag_emplInSubIndustryCountryTotal___?: string;
  market_frag_emplInSubIndustryCountryHHI___?: number;
  market_frag_emplInSubIndustryCountryLargestMktShare___?: number;
  market_frag_emplInSubIndustryCountryLargestCo___?: string;
  market_frag_emplInSubIndustryCountryTotalCo___?: number;
  market_mna_MNAGrowth_12to24Mo__?: number;
  market_mna_MNAGrowth_24to36Mo__?: number;
  market_mna_peMNAGrowth_12to24Mo__?: null;
  market_mna_peMNAGrowth_24to36Mo__?: number;
  market_mna_corpMNAGrowth_12to24Mo__?: number;
  market_mna_corpMNAGrowth_24to36Mo__?: number;
  market_mna_corpMNASameRegionGrowth_12to24Mo__?: number;
  market_mna_corpMNASameRegionGrowth_24to36Mo__?: number;
  market_mna_corpMNADiffRegionGrowth_12to24Mo__?: null;
  market_mna_corpMNADiffRegionGrowth_24to36Mo__?: number;
  market_mna_corpMNASameCountryGrowth_12to24Mo__?: number;
  market_mna_corpMNASameCountryGrowth_24to36Mo__?: number;
  market_mna_corpMNADiffCountryGrowth_12to24Mo__?: null;
  market_mna_corpMNADiffCountryGrowth_24to36Mo__?: number;
  market_mna_corpMNASameSectorGrowth_12to24Mo__?: null;
  market_mna_corpMNASameSectorGrowth_24to36Mo__?: number;
  market_mna_corpMNADiffSectorGrowth_12to24Mo__?: number;
  market_mna_corpMNADiffSectorGrowth_24to36Mo__?: number;
  market_mna_corpMNASameIndustryGrowth_12to24Mo__?: null;
  market_mna_corpMNASameIndustryGrowth_24to36Mo__?: number;
  market_mna_corpMNADiffIndustryGrowth_12to24Mo__?: number;
  market_mna_corpMNADiffIndustryGrowth_24to36Mo__?: number;
  market_mna_corpMNASameSubindustryGrowth_12to24Mo__?: null;
  market_mna_corpMNASameSubindustryGrowth_24to36Mo__?: number;
  market_mna_corpMNADiffSubindustryGrowth_12to24Mo__?: number;
  market_mna_corpMNADiffSubindustryGrowth_24to36Mo__?: number;
  parent_financials_hasParentDiffRevGrowth_1yr__?: null;
  parent_financials_hasParentDiffRevGrowth_1yr__metadata?: null;
  parent_financials_hasParentDiffRevGrowth_3yr__?: null;
  parent_financials_hasParentDiffRevGrowth_3yr__metadata?: null;
  parent_financials_hasParentDiffEbitdaMargin___?: null;
  parent_financials_hasParentDiffEbitdaMargin___metadata?: null;
  child_financials_hasChildDiffRevGrowth_1yr__?: string;
  child_financials_hasChildDiffRevGrowth_1yr__metadata?: string;
  child_financials_hasChildDiffRevGrowth_3yr__?: string;
  child_financials_hasChildDiffRevGrowth_3yr__metadata?: string;
  child_financials_hasChildDiffEbitdaMargin___?: string;
  child_financials_hasChildDiffEbitdaMargin___metadata?: string;
  parent_sector_sector___?: null;
  parent_sector_industry___?: null;
  parent_sector_industryGroup___?: null;
  parent_sector_subIndustry___?: null;
  parent_sector_hasParentDiffSector___?: null;
  parent_sector_hasParentDiffSector___metadata?: null;
  parent_sector_hasParentDiffIndustryGroup___?: null;
  parent_sector_hasParentDiffIndustryGroup___metadata?: null;
  parent_sector_hasParentDiffIndustry___?: null;
  parent_sector_hasParentDiffIndustry___metadata?: null;
  child_sector_hasChildDiffSector___?: string;
  child_sector_hasChildDiffSector___metadata?: string;
  child_sector_hasChildDiffIndustryGroup___?: string;
  child_sector_hasChildDiffIndustryGroup___metadata?: string;
  child_sector_hasChildDiffIndustry___?: string;
  child_sector_hasChildDiffIndustry___metadata?: string;
  parent_ids_id__cdp_?: null;
  parent_firm_typeL1___?: null;
  self_ind_imminentLeadershipChange___generationalChange?: number;
  self_ind_isFamilyOwned___generationalChange?: number;
  self_ind_recentChange___leadershipChange?: number;
  self_ind_newsMentions___leadershipChange?: number;
  self_ind_newsMentionsGrowth___leadershipChange?: number;
  self_ind_newsMentions___growthAmbition?: number;
  self_ind_newsMentionsGrowth___growthAmbition?: number;
  self_ind_hhiIndex___platformConsolidation?: number;
  market_ind_hasManyPlayers___platformConsolidation?: number;
  market_ind_hasLargePlayers___platformConsolidation?: number;
  self_ind_inTop5Players___platformConsolidation?: null;
  self_ids_id__delphai_?: string;
  self_firmo_revenuePercentile___?: number;
  self_firmo_revenuePercentile___metadata?: string;
  self_firmo_revenuePercentile___confidence?: number;
  self_financials_netDebt___?: number;
  self_financials_netDebt___max?: number;
  self_financials_netDebt___min?: number;
  self_financials_netDebt___metadata?: string;
  self_financials_netDebt___confidence?: number;
  self_financials_totalDebt___?: number;
  self_financials_totalDebt___max?: number;
  self_financials_totalDebt___min?: number;
  self_financials_totalDebt___metadata?: string;
  self_mna_holdPeriodStd___confidence?: number;
  parent_mna_acquisitionDateMostRecent___confidence?: number;
  parent_mna_acquisitionCount_1yr__confidence?: number;
  parent_mna_divestitureCount_1yr__confidence?: number;
  parent_mna_acquisitionCount_3yr__confidence?: number;
  parent_mna_divestitureCount_3yr__confidence?: number;
  parent_mna_acquisitionCount_5yr__confidence?: number;
  parent_mna_divestitureCount_5yr__confidence?: number;
  self_mna_divestitureCount_1yr__max?: number;
  self_mna_divestitureCount_1yr__min?: number;
  self_mna_divestitureCount_1yr__metadata?: string;
  self_mna_divestitureCount_1yr__confidence?: number;
  self_mna_divestitureCount_3yr__max?: number;
  self_mna_divestitureCount_3yr__min?: number;
  self_mna_divestitureCount_3yr__metadata?: string;
  self_mna_divestitureCount_3yr__confidence?: number;
  self_mna_divestitureCount_5yr__max?: number;
  self_mna_divestitureCount_5yr__min?: number;
  self_mna_divestitureCount_5yr__metadata?: string;
  self_mna_divestitureCount_5yr__confidence?: number;
  self_mna_mostRecentTransaction___metadata?: string;
  self_mna_secondMostRecentTransaction___metadata?: string;
  self_mna_secondMostRecentTransaction___confidence?: number;
  self_mna_mostRecentTransaction___confidence?: number;
  self_mna_isOwnedByPe___confidence?: number;
  self_mna_holdPeriodAvg___confidence?: number;
  self_mna_divestedPortfolioCount___max?: number;
  self_mna_divestedPortfolioCount___min?: number;
  self_mna_divestedPortfolioCount___metadata?: string;
  self_mna_divestedPortfolioCount___confidence?: number;
  self_mna_currentPortfolioNames___metadata?: string;
  self_mna_currentPortfolioCount___max?: number;
  self_mna_currentPortfolioCount___min?: number;
  self_mna_currentPortfolioCount___metadata?: string;
  self_mna_currentPortfolioCount___confidence?: number;
  self_mna_currentPortfolioAvgAge___max?: number;
  self_mna_currentPortfolioAvgAge___min?: number;
  self_mna_currentPortfolioAvgAge___metadata?: string;
  self_mna_currentPortfolioAvgAge___confidence?: number;
  self_mna_acquisitionCount_5yr__max?: number;
  self_mna_acquisitionCount_5yr__min?: number;
  self_mna_acquisitionCount_5yr__metadata: string;
  self_mna_acquisitionCount_5yr__confidence?: number;
  self_mna_acquisitionCount_3yr__max?: number;
  self_mna_acquisitionCount_3yr__min?: number;
  self_mna_acquisitionCount_3yr__metadata?: string;
  self_mna_acquisitionCount_3yr__confidence?: number;
  self_mna_acquisitionCount_1yr__max: number;
  self_mna_acquisitionCount_1yr__min: number;
  self_mna_acquisitionCount_1yr__metadata: string;
  self_mna_acquisitionCount_1yr__confidence: number;
  self_firmo_employeePercentile___?: number;
  self_firmo_employeePercentile___metadata?: string;
  self_firmo_employeePercentile___confidence?: number;
  self_financials_totalCurrentAssets___?: number;
  self_financials_totalCurrentAssets___max?: number;
  self_financials_totalCurrentAssets___min?: number;
  self_financials_totalCurrentAssets___metadata?: string;
  self_financials_totalCurrentLiabilities___?: number;
  self_financials_totalCurrentLiabilities___max?: number;
  self_financials_totalCurrentLiabilities___min?: number;
  self_financials_totalCurrentLiabilities___metadata?: string;
  market_ind_hasMidSizedPlayers___platformConsolidation?: number;
  self_ind_hasReasonableMarketShare___platformConsolidation?: null;
  self_ind_marketMNAAcquisitions___sectorMNA?: number;
  self_ind_propCorpAcquisitions___sectorMNA?: number;
  self_ind_marketMNAGrowth___sectorMNA?: number;
  self_ind_propHoldPeriodsEnding___sectorMNA?: number;
  self_ind_newsMentions___potentialCarveOut?: number;
  parent_ind_hasDifferentSector___potentialCarveOut?: null;
  parent_ind_outsideOfEurope___potentialCarveOut?: null;
  parent_ind_hasDispersedSubs___potentialCarveOut?: null;
  parent_ind_hasMoreThan5Subsidiaries___potentialCarveOut?: null;
  parent_ind_hasLessThan3SubsInSector___potentialCarveOut?: null;
  self_ind_distanceFromParentSector___potentialCarveOut?: null;
  self_ind_isNotParent___potentialCarveOut?: number;
  self_ind_divergingSubsidiaryPerformance___potentialCarveOut?: number;
  self_ind_acquisition1yrScore___internalMNA?: number;
  self_ind_acquisition1yrGrowth___internalMNA?: number;
  self_ind_newsMentions___internalMNA?: number;
  self_ind_newsMentionsGrowth___internalMNA?: number;
  self_ind_weakDebtCurrentRatio___debtMaturity?: number;
  self_ind_weakDebtLTVRatio___debtMaturity?: number;
  self_ind_newsMentions___debtMaturity?: string;
  self_ind_themeFlag___generationalChange?: string;
  self_ind_themeFlag___debtMaturity?: string;
  self_ind_themeFlag___leadershipChange?: string;
  self_ind_themeFlag___internalMNA?: string;
  self_ind_themeFlag___sectorMNA?: string;
  self_ind_themeFlag___potentialCarveOut?: string;
  self_ind_themeFlag___platformConsolidation?: string;
  self_ind_themeFlag___growthAmbition?: string;
  self_financials_ebitdaMargin___?: number;
  self_financials_ebitdaMargin___min?: number;
  self_financials_ebitdaMargin___max?: number;
  self_financials_ebitdaMargin___confidence?: number;
  self_financials_ebitdaMargin___metadata?: string;
  self_financials_netIncomeMargin___?: number;
  self_financials_netIncomeMargin___min?: number;
  self_financials_netIncomeMargin___max?: number;
  self_financials_netIncomeMargin___confidence?: number;
  self_financials_netIncomeMargin___metadata?: string;
  self_financials_revenueGrowth_1quarter__?: number;
  self_financials_revenueGrowth_1quarter__metadata?: string;
  self_financials_annualRevenueVolatility___?: number;
  self_financials_revenueProfile___?: string;
  self_financials_annualRevenueVolatility___metadata?: string;
  self_firm_statusL1___?: string;
  self_firm_statusL1___metadata?: string;
  self_firm_statusL1___confidence?: number;
  self_firm_statusL2___?: string;
  self_firm_statusL2___metadata?: string;
  self_firm_statusL2___confidence?: number;
  self_mna_mostRecentTransaction___?: number;
  self_mna_yrsSinceMostRecentTransaction___?: number;
  self_mna_secondMostRecentTransaction___?: number;
  self_mna_yrsSinceSecondMostRecentTransaction___?: number;
  self_mna_currentPortfolioCount___?: number;
  self_mna_currentPortfolioNames___?: string;
  self_mna_currentPortfolioAvgAge___?: number;
  self_mna_holdPeriodAvg___?: null;
  self_mna_holdPeriodStd___?: null;
  self_mna_divestedPortfolioCount___?: number;
  self_mna_divestedPortfolioNames___?: string;
  self_mna_acquisitionCount_1yr__?: number;
  self_mna_acquisitionCount_3yr__?: number;
  self_mna_acquisitionCount_5yr__?: number;
  self_mna_divestitureCount_1yr__?: number;
  self_mna_divestitureCount_3yr__?: number;
  self_mna_divestitureCount_5yr__?: number;
  self_mna_ownerNames___?: null;
  self_mna_isOwnedByPe___?: string;
  parent_mna_acquisitionDateMostRecent___?: null;
  parent_mna_acquisitionCount_1yr__?: null;
  parent_mna_acquisitionCount_3yr__?: null;
  parent_mna_acquisitionCount_5yr__?: null;
  parent_mna_divestitureCount_1yr__?: null;
  parent_mna_divestitureCount_3yr__?: null;
  parent_mna_divestitureCount_5yr__?: null;
  self_execs_genderRatio__bdex_?: string;
  self_execs_succession__bdex_?: string;
  self_execs_attrition__bdex_?: string;
  self_execs_attrition_3yr_bdex_?: string;
  self_execs_boardAvgAge__bdex_?: number;
  self_execs_boardAvgRoleTenure__bdex_?: number;
  self_execs_boardAvgCoTenure__bdex_?: number;
  self_firm_isFamilyCo__bdex_?: string;
  self_execs_familySurname__bdex_?: string;
  self_execs_MinFamilySurnameCount__bdex_?: number;
  self_execs_MaxFamilySurnameCount__bdex_?: number;
  self_execs_percentFamily__bdex_?: number;
  self_execs_chairAge__bdex_?: number;
  self_execs_chairRoleTenure__bdex_?: number;
  self_execs_chairCoTenure__bdex_?: number;
  self_execs_chairIsFamily__bdex_?: string;
  self_execs_chairRecentChange__bdex_?: null;
  self_execs_ceoAge__bdex_?: number;
  self_execs_ceoRoleTenure__bdex_?: number;
  self_execs_ceoCoTenure__bdex_?: number;
  self_execs_ceoIsFamily__bdex_?: string;
  self_execs_ceoRecentChange__bdex_?: ExecsAnnounceEffectiveDateRecencyCat;
  self_execs_cfoAge__bdex_?: number;
  self_execs_cfoRoleTenure__bdex_?: number;
  self_execs_cfoCoTenure__bdex_?: number;
  self_execs_cfoIsFamily__bdex_?: string;
  self_execs_cfoRecentChange__bdex_?: null;
  self_execs_anyExecRecentChange__bdex_?: string;
  self_news_AcquisitionsMergersShareholdings_0to3Mo__?: number;
  self_news_AcquisitionsMergersShareholdings_3to6Mo__?: number;
  self_news_CorporateDebtInstruments_0to3Mo__?: number;
  self_news_CorporateDebtInstruments_3to6Mo__?: number;
  self_news_CorporateDueDiligence_0to3Mo__?: number;
  self_news_CorporateDueDiligence_3to6Mo__?: number;
  self_news_CorporateOrganizationalRestructuring_0to3Mo__?: number;
  self_news_CorporateOrganizationalRestructuring_3to6Mo__?: number;
  self_news_CorporateStrategyPlanning_0to3Mo__?: number;
  self_news_CorporateStrategyPlanning_3to6Mo__?: number;
  self_news_DistressedDebt_0to3Mo__?: number;
  self_news_DistressedDebt_3to6Mo__?: number;
  self_news_Divestments_0to3Mo__?: number;
  self_news_Divestments_3to6Mo__?: number;
  self_news_FinancialPerformance_0to3Mo__?: number;
  self_news_FinancialPerformance_3to6Mo__?: number;
  self_news_LeveragedLoans_0to3Mo__?: number;
  self_news_LeveragedLoans_3to6Mo__?: number;
  self_news_MezzanineFinancing_0to3Mo__?: number;
  self_news_MezzanineFinancing_3to6Mo__?: number;
  self_news_OwnershipChanges_0to3Mo__?: number;
  self_news_OwnershipChanges_3to6Mo__?: number;
  self_news_PrivatePlacements_0to3Mo__?: number;
  self_news_PrivatePlacements_3to6Mo__?: number;
  self_mna_yrsSinceMostRecentTransaction___max?: number;
  self_mna_yrsSinceMostRecentTransaction___min?: number;
  self_mna_yrsSinceMostRecentTransaction___metadata?: string;
  self_mna_yrsSinceMostRecentTransaction___confidence?: number;
  self_mna_yrsSinceSecondMostRecentTransaction___max?: number;
  self_mna_yrsSinceSecondMostRecentTransaction___min?: number;
  self_mna_yrsSinceSecondMostRecentTransaction___metadata?: string;
  self_mna_yrsSinceSecondMostRecentTransaction___confidence?: number;
  self_ind_weakDebtCurrentRatio___debtMaturity_metadata?: string;
  self_ind_weakDebtLTVRatio___debtMaturity_metadata?: string;
  SELF_FUNDING_LATESTFUNDINGROUNDINVESTORCBID__CB?: string;
  SELF_FUNDING_LATESTFUNDINGROUNDDATE__CB?: number;
  SELF_LOCATION_SUBCOUNTRYREGION__BQ?: string;
  SELF_FUNDING_LATESTFUNDINGROUNDLEADINVESTORBAINID__CB?: string;
  SELF_FUNDING_LATESTFUNDINGROUNDCBID__CB?: string;
  SELF_FUNDING_TOTALFUNDINGAMOUNTRAISED__CB?: number;
  SELF_LOCATION_ZIPCODE_SHORT__ZOOMINFO?: string;
  SELF_LOCATION_ZIPCODE_SHORT__CRUNCHBASE?: string;
  SELF_LOCATION_ZIPCODE__ZOOMINFO?: string;
  SELF_LOCATION_ZIPCODE__CIQ?: string;
  SELF_LOCATION_ZIPCODE_SHORT__CIQ?: string;
  SELF_FUNDING_LATESTFUNDINGROUNDINVESTORTYPES__CB?: string;
  SELF_FUNDING_LATESTFUNDINGROUNDLEADINVESTORCBID__CB?: string;
  SELF_FUNDING_LATESTFUNDINGROUNDAMOUNTRAISED__CB?: number;
  SELF_LOCATION_SUBCOUNTRYREGION__CRUNCHBASE?: string;
  SELF_FIRMO_BUSINESSMODEL__ZI?: string | string[];
  self_firmo_businessmodel___?: string | string[];
  SELF_LOCATION_ZIPCODE__BQ?: string;
  SELF_FUNDING_LATESTFUNDINGROUNDINVESTORBAINID__CB: '';
  SELF_LOCATION_ZIPCODE_SHORT__BQ?: string;
  SELF_LOCATION_ZIPCODE__CRUNCHBASE?: string;
  SELF_LOCATION_SUBCOUNTRYREGION__CIQ?: string;
  SELF_LOCATION_SUBCOUNTRYREGION__ZOOMINFO?: string;
  SELF_FUNDING_TOTALNUMBEROFFUNDINGROUNDS__CB?: number;
  self_execs_FoundersLed?: 'Y' | 'N';
  SELF_PEOPLE_HEADCOUNTTREND___?: 'Growing' | 'Stable' | 'Declining' | 'NA';
}

export enum DataSource {
  Arx = 'Arx',
  Capiq = 'capiq',
}

export enum ExecsAnnounceEffectiveDateRecencyCat {
  The100DaysOld = '>100 days old',
  Upcoming = 'Upcoming',
}

export enum ExecsPpl {
  No = 'No',
  Yes = 'Yes',
}

export enum ExecsPplDateEndRoleDisplay {
  Current = 'Current',
}

export enum ExecsPplGender {
  F = 'F',
  M = 'M',
}

export enum ExecsPplRoleType {
  ExecBoardMember = 'Exec Board Member',
  NonExecBoardMember = 'Non Exec Board Member',
  NotBoardMember = 'Not Board Member',
}

export enum ExecsPplRowType {
  ListedOrganisations = 'Listed Organisations',
}

export enum Metric {
  AccountsPayableTotal = 'Accounts Payable, Total',
  AccountsReceivableTotal = 'Accounts Receivable, Total',
  AverageDaysPayableOutstanding = 'Average Days Payable Outstanding',
  CapitalExpenditure = 'Capital Expenditure',
  CashAndEquivalents = 'Cash And Equivalents',
  CurrentRatio = 'Current Ratio',
  Ebit = 'EBIT',
  Ebitda = 'EBITDA',
  FullTimeEmployees = 'Full Time Employees',
  GrossProfit = 'Gross Profit',
  Inventory = 'Inventory',
  NetDebt = 'Net Debt',
  NetIncomeIS = 'Net Income - (IS)',
  NetPropertyPlantAndEquipment = 'Net Property Plant And Equipment',
  OtherOperatingExpenses = 'Other Operating Expenses',
  Revenues = 'Revenues',
  ShortTermBorrowings = 'Short-term Borrowings',
  TotalAssets = 'Total Assets',
  TotalCapital = 'Total Capital',
  TotalCashAndShortTermInvestments = 'Total Cash And Short Term Investments',
  TotalCommonEquity = 'Total Common Equity',
  TotalCurrentAssets = 'Total Current Assets',
  TotalCurrentLiabilities = 'Total Current Liabilities',
  TotalDebt = 'Total Debt',
  TotalEmployees = 'Total Employees',
  TotalEquity = 'Total Equity',
  TotalLiabilitiesAndEquity = 'Total Liabilities And Equity',
  TotalLiabilitiesStandardUtilityTemplate = 'Total Liabilities - (Standard / Utility Template)',
  TotalLongTermLiabilities = 'Total Long-Term Liabilities',
  TotalReceivables = 'Total Receivables',
  TotalRevenues = 'Total Revenues',
  TotalRevenues1YrGrowth = 'Total Revenues, 1 Yr. Growth %',
  TotalRevenues3YrCAGR = 'Total Revenues, 3 Yr. CAGR %',
}

export enum PeriodType {
  Annual = 'Annual',
  Quarterly = 'Quarterly',
}

export enum Statement {
  BS = 'B/S',
  IS = 'I/S',
  Other = 'Other',
}

export enum TopicGrouping {
  Corporate = 'Corporate',
  Economic = 'Economic',
  Empty = '',
  Market = 'Market',
  Miscellaneous = 'Miscellaneous',
  SuggestedReading = 'Suggested Reading',
  Traditional = 'Traditional',
}

export enum Units {
  Days = 'Days',
  Empty = '#',
  Ratio = 'Ratio',
  Units = '%',
  Usd = 'USD',
}
