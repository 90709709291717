import dayjs from 'dayjs';

import { ChartDataType } from '@/types';

export const getColor = (d: number) => {
  return d > 1000 ? '#800026' :
    d > 500 ? '#bd0026' :
      d > 200 ? '#e31a1c' :
        d > 100 ? '#fc4e2a' :
          d > 50 ? '#fd8d3c' :
            d > 20 ? '#feb24c' :
              d > 10 ? '#fed976' :
                '#ffeda0';
}

export const getTimePoints = (data: ChartDataType[]) => {
  const set = new Set<string>();

  data.forEach(item => set.add(item.yearmonth as string));

  return Array.from(set.values())
    .sort()
    .map(date => ({
      value: date,
      label: dayjs(date).format('MMM YYYY'),
    }));
};

export const getEmployeesByCountry = (targetMonth: string, data: ChartDataType[]) => {
  return data.reduce((acc, item) => {
    if (item.yearmonth === targetMonth) {
      acc[item.iso3] = item.employees as number;
    }

    return acc;
  }, {} as Record<string, number>);
};