import React, { ReactNode } from 'react';
import Tooltip from '@mui/material/Tooltip';
import classNames from 'classnames';

interface IProps {
  iconUrl: string;
  size?: 'small' | 'medium';
  tooltip?: ReactNode;
}

export const IconWithTooltip = ({ iconUrl, size = 'medium', tooltip }: IProps) => {
  const logo = (
    <div
      className={classNames('bg-no-repeat bg-cover bg-center rounded', {
        'w-[20px] h-[20px]': size === 'small',
        'w-[24px] h-[24px]': size === 'medium',
      })}
      style={{ backgroundImage: `url(${iconUrl})` }}
    />
  );

  return tooltip ? (
    <Tooltip
      title={tooltip}
      enterDelay={300}
    >
      {logo}
    </Tooltip>
  ) : (
    <>{logo}</>
  );
};
