import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { GeoJsonObject } from 'geojson';

import { useShallowSelector } from '../../use-shallow-selector';

export const useQueryGeoJson = () => {
  const isAuth = useShallowSelector((state) => state.user.isAuth);
  const { enqueueSnackbar } = useSnackbar();

  const queryFn = (): Promise<GeoJsonObject> => fetch('/geo-data.json').then(res => res.json());

  return useQuery(['geo-json'], queryFn, {
    enabled: isAuth,
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: true,
    retryDelay: 3,
    onError: () =>
      enqueueSnackbar('Failed to fetch Geo Json. Please try again or contact support.', {
        variant: 'error',
      }),
  });
};
